/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import FilterView from '../../common/components/FilterView';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';
import {
  updateSelectedBlListMultiPayTable,
  updateSelectedRowsInvoice,
} from '../multiBlPayment/slices/multiBlPaymentSlice';
import { setPayPalPopUp, toggleColumnSettingsPaymentList } from './paymentSlice';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import modalImage from '../../../assets/images/PaypalUSA.png'

import queryString from 'query-string';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

//todo bllist
// import { getInvoiceList } from "../dashboard/actions/dashboard";
import OblDetails from '../../common/components/OblDetails';
import {
  AESDecrypt,
  AESEncrypt,
} from '../../../encrypt-util';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';

import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateParams,
} from '../../common/components/DataTable/slices/DataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { updateCurrentPageInvoiceDashboard } from '../dashboard/slices/dashboardSlice';
import {
  updateDashbaordName,
} from '../../common/slices/navbarTop';
import Header from '../../common/components/DetailsViewHeader';

import moment from 'moment';
import DashboardContainer from '../../../components/common/DashboardContainer';
import StandaloneInvoicePayment from '../blPayment/StandaloneInvoicePayment';
import DocumentHistory from '../blDetails/DocumentHistory';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import { useInvDashName } from '../../../hooks/useDashName';

const Payment = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  // const [currentPage, setCurrentPage] = useState<number>(1);

  const currentPageInvoice = useSelector(
    (state: RootState) => state.dashboard.currentPageInvoice
  );
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  const data = useSelector(
    (state: RootState) => state.dashboard.invoiceDashboardList
  );
  const selectedRowsInvoice = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsInvoice
  );

  const paymentStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showPayment
  );
  const releaseStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showRelease
  );
  const instructionStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showInstruction
  );
  const [instructionStatusNew, setInstructionStatusNew] = useState();

  const dataLoading = useSelector(
    (state: RootState) => state.payment.dataLoading
  );


  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const invoiceGrids = useSelector(
    (state: RootState) => state.dataTable.invoiceGrids
  );
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: invoiceGrids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: invoiceGrids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );

  const payPalPopUp = useSelector(
    (state: RootState) => state.payment.paypalPop
  );

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInvoiceDashboard(page));
  };

  const invoiceTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.invoiceTopSearchState
  );
  const loading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const invDashboardName = useInvDashName()

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || '';
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const invoiceDashboardDefinition =
      invoiceGrids?.[0]?.columnConfigGrid || '';

    if (!invoiceDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(invoiceDashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [invoiceGrids, location]);

  useEffect(() => {
    if (
      selectedAmount.length === 0 &&
      location.pathname.split('/')[0] === 'payment'
    ) {
      navigate(`/payment${location.search}`);
    }
  }, [selectedAmount]);

  useEffect(() => {
    dispatch(updateDashbaordName(invDashboardName));
  }, []);
  useEffect(() => {
    if (refresh) loadPaymentDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  const filterStateLocal = filterState?.['paymentOne'];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  useEffect(() => {
    if (
      location?.pathname &&
      dataCountry &&
      linerId
    ) {
      setTimeout(() => {
        dispatch(
          getColumnViews({
            gridName: 'INV_DASHBOARD',
            country: dataCountry,
            linerId: linerId,
          })
        );
      }, 150);
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d, i) => {
      if (
        selectedRowsInvoice.includes(d.invid) &&
        !d?.paymentRefNo &&
        !(d?.paidIndicator == '1')
      )
        selections[i] = true;
    });

    dispatch(updateSelectedBlListMultiPayTable(selections));
  }, [selectedRowsInvoice, data]);

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'blsliderinvoice') {
      setObldetails(true);
    } else if (location.pathname === '/payment') {
      setObldetails(false);
    }
  }, [location.pathname, dataCountry, invoiceGrids]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: invoiceGrids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: invoiceGrids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, invoiceGrids]);

  // useEffect(() => {
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
  //     loadPaymentDashboard(true);
  //     setPreviousState(currentState);
  //   }
  // }, [currentState]);

  useDebouncedEffect(
    () => {
      if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
        loadPaymentDashboard(true);
        setPreviousState(currentState);
      }
    },
    [currentState],
    700
  );

  useEffect(() => {
    loadPaymentDashboard(false);
  }, [currentPageInvoice, linerId]);

  const loadPaymentDashboard = (filterUpdated: boolean) => {
    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any =
            selectedTopSearchFilter?.id && searchInputValue.trim() != ''
              ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
              : {};

          if (invoiceGrids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = invoiceGrids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['paymentOne']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString();
              // const newDate = new Date(
              //   new Date(currentDate.toISOString()).getTime() -
              //     timeDiff * 24 * 60 * 60 * 1000
              // );
              filterApiState.invdt[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              invoiceGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  v2: true,
                  gridName: invDashboardName,
                  payload: {
                    fields: /*  SelectedViewFields?.fields || */[],
                    filters:
                      selectedTopSearchFilter?.where == ''
                        ? finalFilterApiState
                        : {},

                    size: 20,
                    sorts:
                      sortState &&
                        sortState?.key != null &&
                        sortState?.value != null
                        ? [`${sortState?.key} ${sortState?.value}`]
                        : sort
                          ? [sort]
                          : ['invid desc'],
                    // page: currentPageInvoice,
                    page: filterUpdated ? 1 : currentPageInvoice,
                  },
                  dataCountry: dataCountry,
                  partnerId:
                    profileDetails.partnerType === 'customer'
                      ? profileDetails.partnerId
                      : null,
                  where: invoiceGrids?.[0]?.whereClause
                    ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ''
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
            }, 250);
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            invoiceGrids?.[0]?.whereClause) ||
          profileDetails?.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                v2: true,
                gridName: invDashboardName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where === ''
                      ? invoiceTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                      sortState?.key != null &&
                      sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                        ? [sort]
                        : ['invid desc'],
                  // page: currentPageInvoice,
                  page: filterUpdated ? 1 : currentPageInvoice,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: invoiceGrids?.[0]?.whereClause
                  ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250);
        }
      }
    } else {
      if (
        !location.pathname.includes('blsliderinvoice/') &&
        location.pathname.includes('/payment') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            invoiceGrids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                v2: true,
                gridName: invDashboardName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where == ''
                      ? invoiceTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                      sortState?.key != null &&
                      sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                        ? [sort]
                        : ['invid desc'],
                  //page: currentPageInvoice,
                  page: filterUpdated ? 1 : currentPageInvoice,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: invoiceGrids?.[0]?.whereClause
                  ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250);
        }
      }
    }
  };

  const { t } = useTranslation();
  const [obldetails, setObldetails] = useState(false);
  const selectionDisabledForLiner =
    profileDetails.partnerType?.toLowerCase() === 'liner' ? false : true;
  const showColumnSettings = useSelector(
    (state: RootState) => state.payment.showColumnSettings
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsPaymentList(false));
  };

  const selectionInitialState = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPayTable
  );

  const _onSelect = (data: any) => {
    // dispatch(updateSelectedBlListMultiPay([...selectedBlData,data.list]));
  };
  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    // if (location?.pathname) {
    //   dispatch(
    //     getColumnViews({ gridName: 'INV_DASHBOARD', country: dataCountry })
    //   );
    // }
    dispatch(updateParams({}));
    // dispatch(
    //   updateSortStateDataTable({
    //     key: null,
    //   })
    // );
  }, [dataCountry]);

  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d, i) => {
      if (
        selectedRowsInvoice.includes(d.invid) &&
        !d?.paymentRefNo &&
        !(d?.paidIndicator == '1')
      )
        selections[i] = true;
    });

    dispatch(updateSelectedBlListMultiPayTable(selections));
  }, [selectedRowsInvoice, data]);

  const rbac = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const sideWidth = useMemo(
    () =>
      Object.entries({
        [`/payment`]: 0,
        [`/blsliderpayment/:blNum`]: 6,
        [`/payment/addInvoice/invoicePayment`]: 6,
        [`/payment/new`]: 6,
        [`/payment/addInvoiceRequest`]: 6,
        [`/payment/addInvoice`]: 6,
        [`/payment/bl/:blNum/unpaid`]: 6,
        [`/payment/bl/:blNum/unpaid/payment`]: 6,
      }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9,
    [location.pathname]
  );

  const docHistory = location.pathname.includes('payment/bl/');
  const standalone = location.pathname.includes('addInvoice/invoicePayment');
  return (
    <>
      <DashboardContainer
        sideWidth={sideWidth}
        inner={docHistory || standalone}
        innerOffset={!standalone ? 50 : 0}
        middleScroll={
          !docHistory &&
          (['/payment/new', '/payment/addInvoice'].includes(location.pathname) ||
            !matchPath('/payment/:blNum', location.pathname))
        }
        middleChildren={
          blslider == 'blsliderinvoice' ? (
            <OblDetails
              blslider={`/blsliderinvoice/${location.pathname.split('/')[1]}`}
            />
          )
            : docHistory ||
              !(['/payment/new', '/payment/addInvoice'].includes(location.pathname) ||
                !matchPath('/payment/:blNum', location.pathname)) ?
              <div className={`flex-1 h-100 smoothDiv w-${standalone ? 50 : 100}`}>
                <Header
                  backNavRoute={'/payment'}
                  paymentStatus={paymentStatus}
                  instructionStatus={instructionStatusNew}
                  releaseStatus={releaseStatus}
                />
                <DocumentHistory />
              </div>
              : (
                <div className={`flex-1 h-100 smoothDiv w-${standalone ? 50 : 100}`}>
                  {
                    location.pathname.includes('addInvoice') ? (
                      <StandaloneInvoicePayment />
                    )
                      : (
                        <Outlet />
                      )
                  }
                </div>
              )
        }
      >
        <FilterView
          loadList={loadPaymentDashboard}
          showPayButton={rbac.rbacDetails.createUpdatePayment == 'Y' && !!matchPath('/payment', location.pathname)}
          showUploadButton
          showAmount={
            location.pathname.includes('/payment') &&
            (location.pathname.includes('addInvoice') ||
              location.pathname.includes('new') ||
              !matchPath('/payment/:blNum', location.pathname))
          }
          showExport={
            matchPath('/blsliderpayment/:blNum', location.pathname) ? false : true
          }
          showColumnSettings={
            matchPath('/blsliderpayment/:blNum', location.pathname) ? false : true
          }
          dataLoading={dataLoading}
          toggleColumnSettings={toggleColumnSettingsPaymentList}
          columnSettingsPersistKey='@odex/payment/v1'
          uniqueFilterKey='paymentOne'
          configViews={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            invoiceGrids?.find?.((g) => g?.gridName === 'INV_DASHBOARD')
              ?.configGroup || []
          }
          showRightDiv={
            location.pathname === '/payment/new' ||
              location.pathname === '/payment/addInvoice' ||
              location.pathname === `/payment/${encodeURIComponent(blNum || '')}` ||
              obldetails
              ? false
              : true && matchPath('/blsliderpayment/:blNum', location.pathname)
                ? false
                : true
          }
          dashboardName='INV_DASHBOARD'
          data={data}
          gridDef={invoiceGrids}
        />
        {columns?.length > 0 && (
          <DataTable
            data={data?.result || null}
            columns={columns}
            dataCountry={dataCountry || ''}
            sortable
            searchable={false}
            showColumnFilter={false}
            selection={selectionDisabledForLiner}
            perPage={20}
            pagination
            numberPagination
            maxPage={parseInt(data?.maxPage || '100')}
            hasNext={data?.hasNext}
            currentPage={currentPageInvoice}
            gridId={invoiceGrids?.[0]?.gridId || 0}
            onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
            showExport={false}
            showColumnSettings={showColumnSettings}
            onHide={onHideColumnSettings}
            onCustomSelectChange={_onSelect}
            selectionInitialState={selectionInitialState}
            disableRowSelection={{
              accessor: 'payment',
              value: 'Paid',
            }}
            onSelectRow={(dat) => {
              if (dat?.action === 'add') {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsInvoice([
                      ...selectedRowsInvoice,
                      ...dat?.data?.map((d: any) => d.invid),
                    ])
                  );
                } else {
                  dispatch(
                    updateSelectedRowsInvoice([
                      ...selectedRowsInvoice,
                      dat?.data?.invid,
                    ])
                  );
                }
              } else {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsInvoice(
                      selectedRowsInvoice.filter(
                        (f) => !dat?.data?.map((v: any) => v?.invid).includes(f)
                      )
                    )
                  );
                } else {
                  dispatch(
                    updateSelectedRowsInvoice(
                      selectedRowsInvoice.filter((f) => f !== dat?.data?.invid)
                    )
                  );
                }
              }
            }}
            columnSettingsPersistKey='@odex/payment/v1'
            customSort={true}
            uniqueFilterKey='paymentOne'
            clearSelection={() => {
              dispatch(updateSelectedBlListMultiPayTable({}));
            }}
            staticHiddenCols={configs?.staticHiddenCols || []}
            defaultHiddenCols={
              configs?.defaultHiddenCols?.filter((v) => v !== 'id') || []
            }
            tableMinHeight={400}
            gridName='INV_DASHBOARD'
            dataLoading={false}
            configGroup={invoiceGrids?.[0]?.configGroup || []}
          />
        )}

      </DashboardContainer>
      {dataCountry == 'US' && profileDetails?.partnerType === 'customer' ?

        <Modal show={payPalPopUp} size='lg' className='payPalModal'   >
          {/*   <div  className="modal-header px-5 m-1 position-relative  bg-shape">
           <h4 className="mb-0 text-white" >PayPal Service Live Now</h4>
        </div> */}
          <img src={modalImage} style={{ width: '600px' }} className='slide'  ></img>
          <button
            className="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
            onClick={() =>
              dispatch(setPayPalPopUp(false))
            }
          >
          </button>
        </Modal>
        : ""}

    </>
  );
};

export default Payment;
