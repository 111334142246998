/* eslint-disable react-hooks/exhaustive-deps */
import AdvanceTable from "../../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../../../components/common/advance-table/AdvanceTableSearchBox";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import CustomSearch from "./CustomSearch";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import ColumnVisibilitySettings from "./ColumnVisibilitySettings";
import { updateSelectedRow } from "../../../features/dashboard/slices/dashboardSlice";
import {
  faChevronLeft,
  faChevronRight,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  saveColumnViewSettings,
  updateColumnViewSettings,
} from "../../actions/dataTable";
import {
  updateSelectedConfigId,
  updateParams,
  updateSetColsDataTable,
  updateHiddenColsDataTable,
  updateColsDataTable,
} from "./slices/DataTable";
import Marquee from "react-fast-marquee";

const DataTable = ({
  columns,
  data,
  pagination,
  selection,
  sortable,
  perPage,
  searchable,
  showExport,
  showColumnFilter,
  customSearch = false,
  showColumnSettings = false,
  onHide = () => {},
  onCustomSelectChange = (data) => {},
  onSelectRow = (data) => {},
  selectionInitialState = {},
  disableRowSelection = {},
  columnSettingsPersistKey = "",
  bodyClassName = "",
  showPagination = true,
  seekPagination = false,
  next = "",
  previous = "",
  numberPagination = false,
  currentPage = 1,
  maxPage = 5,
  maxHeight = "",
  hasNext = false,
  customSort = false,
  shortPrepend = "",
  staticHiddenCols = [""],
  localFilter = false,
  uniqueFilterKey,
  autoHeight = false,
  defaultHiddenCols = [""],
  clearSelection = () => {},
  tableMinHeight = 200,
  gridId = 0,
  gridName = "BL_DASHBOARD",
  addTbodyBreak = false,
  dataLoading = false,
  configGroup = undefined,

  onChangePageNumber = (pageNum) => {},
  dataCountry = "US",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(false);
  }, [data]);

  const [hiddenCols, setHiddenCols] = useState([...defaultHiddenCols]);
  const [cols, setCols] = useState(
    Array.from(
      new Set(
        columns
          .map((i) => i.accessor)
          .filter((c) => !staticHiddenCols.includes(c))
      )
    )
  );

  const filterState = useSelector((state) => state.dataTable.filterState);
  const sortState = useSelector((state) => state.dataTable.sortState);
  const dateRange = useSelector((state) => state.dataTable.dateRange);
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const grids = useSelector((state) => state.dataTable.grids);

  const paramData = useSelector((state) => state.dataTable.paramPaginationVal);

  const setColsDataTable = useSelector(
    (state) => state.dataTable.setColsDataTable
  );
  const dateState = useSelector((state) => state.dataTable.dateState);

  const hiddenColsDataTable = useSelector(
    (state) => state.dataTable.hiddenColsDataTable
  );
  const colsDataTable = useSelector((state) => state.dataTable.colsDataTable);

  const selectedConfigId = useSelector(
    (state) => state.dataTable.selectedConfigId
  );
  const linerId = useSelector((state) => state.navbarTop.linerId);
  const selectedData = useSelector((state) => state.dashboard.selectedData);

  const _setInitialStateCols = () => {
    dispatch(
      updateHiddenColsDataTable({
        ...hiddenColsDataTable,
        [gridName]: [...defaultHiddenCols],
      })
    );

    dispatch(
      updateColsDataTable({
        [gridName]: columns
          .map((i) => i.accessor)
          .filter((c) => !staticHiddenCols.includes(c)),
      })
    );
  };

  useEffect(() => {
    if (!setColsDataTable.includes(gridName)) {
      dispatch(updateSetColsDataTable([...setColsDataTable, gridName]));

      _setInitialStateCols();
    }
  }, []);

  useEffect(() => {}, [location.pathname.split("/")[1]]);
  useEffect(() => {
    if (selectedConfigId?.[uniqueFilterKey]) {
      const columnConfigs_String = configGroup
        ? configGroup?.find(
            (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey]
          )?.columnConfigGrid
        : grids
            .find((g) => g?.gridName === gridName)
            ?.configGroup?.find(
              (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey]
            )?.columnConfigGrid;

      if (columnConfigs_String) {
        const columnConfigs = JSON.parse(columnConfigs_String);
        const hiddenColsView = columnConfigs
          .filter((c) => c.visible === false)
          .map((m) => m.accessor);
        const hiddenColsAll = columns
          .filter(
            (c) => !columnConfigs.map((m) => m.accessor).includes(c.accessor)
          )
          .map((c) => c.accessor);

        dispatch(
          updateHiddenColsDataTable({
            ...hiddenColsDataTable,
            [gridName]: Array.from(
              new Set([...hiddenColsView, ...hiddenColsAll])
            ),
          })
        );

        dispatch(
          updateColsDataTable({
            [gridName]: Array.from(
              new Set([
                ...columnConfigs.map((m) => m.accessor),
                ...columns.map((i) => i.accessor),
              ])
            ),
          })
        );
        setHiddenCols(
          columnConfigs
            .filter((c) => c.visible === false)
            .map((m) => m.accessor)
        );

        setCols(Array.from(new Set(columnConfigs.map((m) => m.accessor))));
      }
    } else if (selectedConfigId?.[uniqueFilterKey] === null) {
      _setInitialStateCols();
      setHiddenCols([...defaultHiddenCols]);

      setCols(columns.map((i) => i.accessor));
    }
  }, [selectedConfigId]);

  const columnFilter = columns?.map((column) => ({
    accessor: column?.accessor,
    options: Array.from(new Set(data?.map((d) => d[column?.accessor])))
      .sort()
      .filter((v) => v !== ""),
  }));

  const onSaveSettings = (shortcutName, toggleConfigForm, isEdit = false) => {
    const sort = queryString
      .parse(location.search)
      ?.sort?.split?.(",")
      ?.join?.(" ");
    if (gridId) {
      const columnConfig =
        colsDataTable?.[gridName]
          ?.map((cl) => columns.find((v) => v.accessor === cl))
          .map((c) => ({
            accessor: c?.accessor,
            visible: !hiddenColsDataTable?.[gridName]?.includes?.(c?.accessor),
            filterable: true,
            sorts: sort ? [sort] : null,
            filterType: c?.columnFilterType || null,
            filterState:
              (filterState[uniqueFilterKey]?.lastupdateddate != "" &&
                filterState[uniqueFilterKey]?.lastupdateddate != undefined) ||
              (filterState[uniqueFilterKey]?.pmt_time != "" &&
                filterState[uniqueFilterKey]?.pmt_time != undefined) ||
              (filterState[uniqueFilterKey]?.invdt != "" &&
                filterState[uniqueFilterKey]?.invdt != undefined) ||
              (filterState[uniqueFilterKey]?.timestamp != "" &&
                filterState[uniqueFilterKey]?.timestamp != undefined)
                ? dateState
                : null,
            dateRange:
              (filterState[uniqueFilterKey]?.lastupdateddate != "" &&
                filterState[uniqueFilterKey]?.lastupdateddate != undefined) ||
              (filterState[uniqueFilterKey]?.pmt_time != "" &&
                filterState[uniqueFilterKey]?.pmt_time != undefined) ||
              (filterState[uniqueFilterKey]?.invdt != "" &&
                filterState[uniqueFilterKey]?.invdt != undefined) ||
              (filterState[uniqueFilterKey]?.timestamp != "" &&
                filterState[uniqueFilterKey]?.timestamp != undefined)
                ? dateRange
                : null,
            timeDiff:
              filterState[uniqueFilterKey]?.lastupdateddate != "" &&
              filterState[uniqueFilterKey]?.lastupdateddate != undefined
                ? (new Date(filterState[uniqueFilterKey]?.lastupdateddate.to) -
                    new Date(
                      filterState[uniqueFilterKey]?.lastupdateddate.from
                    )) /
                  (1000 * 60 * 60 * 24)
                : filterState[uniqueFilterKey]?.pmt_time != "" &&
                  filterState[uniqueFilterKey]?.pmt_time != undefined
                ? (new Date(filterState[uniqueFilterKey]?.pmt_time.to) -
                    new Date(filterState[uniqueFilterKey]?.pmt_time.from)) /
                  (1000 * 60 * 60 * 24)
                : filterState[uniqueFilterKey]?.invdt != "" &&
                  filterState[uniqueFilterKey]?.invdt != undefined
                ? (new Date(filterState[uniqueFilterKey]?.invdt.to) -
                    new Date(filterState[uniqueFilterKey]?.invdt.from)) /
                  (1000 * 60 * 60 * 24)
                : filterState[uniqueFilterKey]?.timestamp != "" &&
                  filterState[uniqueFilterKey]?.timestamp != undefined
                ? (new Date(filterState[uniqueFilterKey]?.timestamp.to) -
                    new Date(filterState[uniqueFilterKey]?.timestamp.from)) /
                  (1000 * 60 * 60 * 24)
                : null,
            filterValues: [
              {
                accessor: c?.accessor,
                label:
                  JSON.stringify(filterState[uniqueFilterKey]?.[c?.accessor]) ||
                  null,
              },
            ],
          })) || [];

      if (isEdit) {
        dispatch(
          updateColumnViewSettings({
            payload: {
              //  customName: shortcutName.name,
              columnConfigName: shortcutName.name,
              type: (shortcutName?.type || "private").toLowerCase(),
              gridId,
              gridName,
              columnConfig: columnConfig,
              columnConfigGrid: JSON.stringify(columnConfig),
              country: dataCountry,
              linerId:
                linerId !== undefined || "" ? linerId : profileDetails.linerId,
            },
            dispatch,
            userGridId: selectedConfigId?.[uniqueFilterKey] || 0,
          })
        );
      } else {
        dispatch(
          saveColumnViewSettings({
            payload: {
              columnConfigName: shortcutName.name,
              type: (shortcutName?.type || "private").toLowerCase(),
              //gridId,
              gridId,
              gridName,
              columnConfig: columnConfig,
              columnConfigGrid: JSON.stringify(columnConfig),
              country: dataCountry,
              linerId:
                linerId !== undefined || "" ? linerId : profileDetails.linerId,
            },
            dispatch,
          })
        );
      }

      toggleConfigForm(false);
      dispatch(
        updateSelectedConfigId({
          ...selectedConfigId,
          [uniqueFilterKey]: null,
        })
      );
    }
  };
  useEffect(() => {
    if (profileDetails?.partnerType === "liner")
      window.localStorage.removeItem("liner_id");
  }, []);
  const onResetSettings = () => {
    if (columnSettingsPersistKey) {
      window.localStorage.removeItem(columnSettingsPersistKey);
    }

    _setInitialStateCols();

    setHiddenCols([...defaultHiddenCols]);
    setCols(columns.map((i) => i.accessor));
  };

  return (
    <div className="mb-2 d-flex flex-column w-100 h-auto">
      {showColumnSettings && colsDataTable?.[gridName]?.length > 0 ? (
        <ColumnVisibilitySettings
          show={showColumnSettings}
          onHide={onHide}
          columns={columns}
          gridName={gridName}
          cols={
            colsDataTable[gridName].filter((a) => {
              const row = columns.find((v) => v?.accessor === a);
              return row?.Header && !row?.hide;
            }) || []
          }
          setColumns={(clms) => {
            dispatch(
              updateColsDataTable({
                [gridName]: clms,
              })
            );
          }}
          hiddenCols={hiddenColsDataTable?.[gridName] || []}
          setHiddenCols={(clmss) => {
            dispatch(
              updateHiddenColsDataTable({
                ...hiddenColsDataTable,
                [gridName]: clmss,
              })
            );
          }}
          onSaveSettings={onSaveSettings}
          onResetSettings={onResetSettings}
          columnSettingsPersistKey={columnSettingsPersistKey}
          filterState={filterState}
          sortState={sortState}
          uniqueFilterKey={uniqueFilterKey}
          //configViews={grids?.find?.((g) => g?.gridId == gridId)?.configGroup}
          configViews={
            configGroup ||
            grids?.find?.((g) => g?.gridName == gridName)?.configGroup
          }
          dataCountry={dataCountry}
        />
      ) : (
        ""
      )}
      {!searchable && customSearch ? <CustomSearch /> : ""}

      {colsDataTable?.[gridName]?.length > 0 ? (
        <AdvanceTableWrapper
          columns={colsDataTable?.[gridName]
            .map((v) => columns.find((i) => i.accessor === v))
            .filter(
              (value) =>
                value &&
                !hiddenColsDataTable?.[gridName]?.includes?.(value.accessor)
            )}
          data={data}
          sortable={sortable}
          pagination={pagination}
          selection={selection}
          selectionColumnWidth={40}
          perPage={perPage}
          onCustomSelectChange={onCustomSelectChange}
          onSelectRow={onSelectRow}
          selectionInitialState={selectionInitialState}
          disableRowSelection={disableRowSelection}
          staticHiddenCols={staticHiddenCols}
          uniqueFilterKey={uniqueFilterKey}
          selectedRows={selectedData}
        >
          {searchable ? (
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle flex-1"
            rowClassName="align-middle white-space-nowrap"
            bodyClassName={bodyClassName}
            columnFilter={showColumnFilter ? columnFilter : []}
            //onFilterChange={onFilterChange}
            tableProps={{
              bordered: false,
              striped: false,
              className: "fs--1 mb-0 overflow-hidden",
            }}
            cols={colsDataTable?.[gridName]}
            customSort={customSort}
            shortPrepend={shortPrepend}
            staticHiddenCols={staticHiddenCols}
            localFilter={localFilter}
            uniqueFilterKey={uniqueFilterKey}
            tableMinHeight={tableMinHeight}
            addTbodyBreak={addTbodyBreak}
            autoHeight={autoHeight}
            dataLoading={dataLoading}
            maxHeight={maxHeight}
            // forwardedRef={myRef}
          />

          {(data?.length > 0 &&
            showPagination &&
            (pagination || seekPagination || numberPagination)) ||
          showExport ? (
            <div className="mt-2 d-flex flex-row justify-content-between align-items-start ps-2 pe-2">
              {!seekPagination &&
              !numberPagination &&
              pagination &&
              !location.pathname.includes("blsliderpayment") ? (
                <AdvanceTablePagination table />
              ) : (
                ""
              )}
              {seekPagination ? (
                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    size="sm"
                    variant="falcon-secondary"
                    className="me-2"
                    disabled={!previous}
                    onClick={() => {
                      navigate(
                        `${location.pathname}?${queryString.stringify({
                          ...queryString?.parse?.(location?.search),
                          seek: previous,
                          direction: "reverse",
                        })}`
                      );
                      if (paramData.value) {
                        if (paramData.value != undefined) {
                          dispatch(
                            updateParams({
                              ...queryString?.parse?.(location?.search),
                              seek: previous,
                              direction: "reverse",
                              sort: `${paramData.key},${paramData.value}`,
                            })
                          );
                        } else {
                          dispatch(
                            updateParams({
                              ...queryString?.parse?.(location?.search),
                              seek: previous,
                              direction: "reverse",
                            })
                          );
                        }
                      } else if (paramData.sort) {
                        dispatch(
                          updateParams({
                            ...queryString?.parse?.(location?.search),
                            seek: previous,
                            direction: "reverse",
                            sort: paramData.sort,
                          })
                        );
                      } else {
                        dispatch(
                          updateParams({
                            ...queryString?.parse?.(location?.search),
                            seek: previous,
                            direction: "reverse",
                          })
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="me-1" />
                    <span>Previous</span>
                  </Button>
                  <Button
                    size="sm"
                    variant="falcon-secondary"
                    disabled={!next}
                    onClick={() => {
                      navigate(
                        `${location.pathname}?${queryString.stringify({
                          ...queryString?.parse?.(location?.search),
                          direction: undefined,
                          seek: next,
                        })}`
                      );
                      if (paramData.value) {
                        if (paramData.value != undefined) {
                          dispatch(
                            updateParams({
                              ...queryString?.parse?.(location?.search),
                              direction: undefined,
                              seek: next,
                              sort: `${paramData.key},${paramData.value}`,
                            })
                          );
                        } else {
                          dispatch(
                            updateParams({
                              ...queryString?.parse?.(location?.search),
                              direction: undefined,
                              seek: next,
                            })
                          );
                        }
                      } else if (paramData.sort) {
                        dispatch(
                          updateParams({
                            ...queryString?.parse?.(location?.search),
                            direction: undefined,
                            seek: next,
                            sort: paramData.sort,
                          })
                        );
                      } else {
                        dispatch(
                          updateParams({
                            ...queryString?.parse?.(location?.search),
                            direction: undefined,
                            seek: next,
                          })
                        );
                      }
                    }}
                  >
                    <span>Next</span>

                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" />
                  </Button>
                </div>
              ) : numberPagination ? (
                <div className="d-flex justify-content-start align-items-center">
                  <Button
                    size="sm"
                    variant="falcon-secondary"
                    className="me-2"
                    disabled={currentPage === 1 || pageLoading}
                    onClick={() => {
                      onChangePageNumber(currentPage - 1);
                      setPageLoading(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="me-1" />
                    <span>Previous</span>
                  </Button>

                  <Button
                    size="sm"
                    variant="falcon-secondary"
                    // disabled={currentPage === maxPage}
                    disabled={!hasNext || pageLoading}
                    onClick={() => {
                      onChangePageNumber(currentPage + 1);
                      setPageLoading(true);
                    }}
                  >
                    <span>Next</span>

                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" />
                  </Button>
                </div>
              ) : (
                ""
              )}

              {showExport ? (
                <div className="d-flex justify-content-center align-items-center align-self-end">
                  <Button variant="falcon-primary" className="me-2" size="sm">
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className="text-warning"
                    />
                    &nbsp;&nbsp; PDF
                  </Button>
                  <Button variant="falcon-primary" size="sm">
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      className="text-warning"
                    />
                    &nbsp;&nbsp; CSV
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </AdvanceTableWrapper>
      ) : (
        ""
      )}
    </div>
  );
};

export default DataTable;
