import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch } from 'react-redux';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { updateBlDetailsRowColumns } from '../../blDetails/slices/blDetailsTable';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncDebounce } from 'react-table';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import DataTable from '../../../common/components/DataTable';
import {
  approveRejectPickUp,
  deleveryLocTypeList,
  extensionTypeList,
  getExtensionRequest,
  getInstructionList,
  getOBLDetails,
  getOBLForOverStay,
  getPickUp,
  getReleaseTypeList,
  getReturnTypeList,
  populateExtensionRequest,
  saveExtensionRequest,
  savePickUpInstruction,
} from '../../dashboard/actions/instruction';
import {
  faCheck,
  faCircleInfo,
  faEdit,
  faRedo,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { profile } from 'console';
import { toast } from 'react-toastify';
import { AESDecrypt } from '../../../../encrypt-util';
import i18next from 'i18next';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';

import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useDataCountry from '../../../../hooks/useDataCountry';
import { getDateFormat } from '../../../../helpers/dateFormat';
import { IDataTableColumn } from '../../../common/types/dataTable';
import moment from 'moment';
import { updateSelectedDateDV, updateSelectedPickupLocation, updateSelectedTruckerName, updateSelectedtruckerId, updateselectedInternalCode } from './instructionSlice/DemurrageSlice';
import { clearData, clearTableInputValues, updateSelectedCCyOnContainer, updateSelectedCcy, updateSelectedContainerNo, updateSelectedDeleveryLocType, updateSelectedDeleveryLocTypeOnContainer, updateSelectedPickupDate, updateSelectedPlannedPickupDate, updateSelectedneedExtensionFor } from './instructionSlice/OverstayExtensionSlice';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import SnoozedToButton from '../../instructionDashboard/components/SnoozedTo';
import { getCurrencyDDList } from '../../blPayment/actions/payments';


const OverstayExtensionRequest = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { blNum, encryptdoc } = useParams();
  const docId = AESDecrypt(encryptdoc);
  const [country, setCountry] = useState<any>(null);
  const [clearable, setClearable] = useState(true);
  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const data: any = useSelector((state: RootState) => state.overstay.data);
  const extensionRequestStatus:any = useSelector((state:RootState)=>state.overstay.overstayExtension.status)
  const extensionRequestId:any = useSelector((state:RootState)=>state.overstay.overstayExtension.extensionRequestId)
  const extensionReasonData: any = useSelector((state: RootState) => state.overstay.data.map((item)=>item.needExtensionFor));
  const blEventId_docInsDashboard:any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item:any)=> Item.bl_id == AESDecrypt(blNum) )[0]?.bl_event_id
  );

  const conatainerData = useSelector((state:RootState)=>state.overstay.data)
  const [isEdit, setIsEdit] = useState(false);
  const currencyList = useSelector((state:RootState)=>state.standaloneInvoice.countryDDList)

  const selectedPickupLocation = useSelector(
    (state: RootState) => state.demurrage.selectedPickupLocation
  );

  const selectedNeedExtensionFor = useSelector(
    (state: RootState) => state.overstay.overstayExtension.needExtensionFor
  );

  const selectedPlannedPickupDate = useSelector((state: RootState) => state.overstay.overstayExtension.plannedPickupDate)

  const pickUpLocation = useSelector(
    (state: RootState) => state.demurrage.pickUpLocation
  );
  const accessListLiner =['1','24118']

  const onBack = useBackNavigation();

 const TableCell = (rowData: any) => {
    const { blContainerId, containerNo, plannedPickupDate } = rowData.row.original;

    if (disableFields) {
      return (
        <span>
          {plannedPickupDate?moment(
                      moment
                        .utc(plannedPickupDate)
                        .toDate()
                    ).format(getDateFormat().toLocaleUpperCase()):''}
        </span>
      );
    }
    return (
      <>
        <DatePicker
          selected={plannedPickupDate}
          portalId='root'
          onChange={(date) => {
            /*  dispatch(
               updateSelectedDateDV({
                 ...selectedDate,
                 [containerNo]: date,
                 intended: null,
               })
             ); */
            dispatch(
              updateSelectedPickupDate({
                containerNo,
                plannedPickupDate: date
              })
            );

          }}
          className='fs--2 form-control'
          timeIntervals={5}
          dateFormat={getDateFormat()}
          isClearable={clearable}

          popperProps={{
            strategy: 'fixed',
          }}
          disabled={
            false
            //  selectedPickupLocation?.[containerNo] === '1001' ? true : false
          }
          minDate={new Date()}
        />
        {/*   {!data?.list
          ?.map((d) => (selectedDate?.[d.containerNo] ? true : false))
          .includes(true) &&
          !selectedDate?.[containerNo] && (
            <span className='text-danger f--1 mt-1'> </span>
          )} */}
      </>
    );
  };

  const TableCellReturnLocation = (rowData: any) => {
    const { blContainerId, containerNo,needExtensionFor  } = rowData.row.original;
    if (!disableFields) {
      return (
        <Form.Select
          disabled={disableFields}
          value={needExtensionFor || ''}
          onChange={(e) => {
            dispatch(
              updateSelectedContainerNo({
                containerNo,
                needExtensionFor: e.target.value
              })
            );


          }}
          className='fs--1 form-control  form-select'>
          {
            <>
              {extensionTypeOption.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </>
          }
        </Form.Select>
      );
    } else {
      return (
        <span>
         
          {extensionTypeOption?.find?.((i)=>i.value == needExtensionFor)?.text || ''}
        </span>
      );
    }
  };

  const TableCellDeleveryLocType = (rowData: any) => {
    const { blContainerId, containerNo, deliveryLocationType,needExtensionFor } = rowData.row.original;
    if (!disableFields) {
      return (
        <Form.Select
          disabled={disableFields}
          value={deliveryLocationType || ''}
          onChange={(e) => {
            dispatch(
              updateSelectedDeleveryLocTypeOnContainer({
                containerNo,
                deliveryLocationType: e.target.value
              })
            );


          }}
          className='fs--1 form-control  form-select'>
          {
            <>
              {deleveryLocationList.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </>
          }
        </Form.Select>
      );
    } else {
      return (
        <span>
         
          {deleveryLocationList?.find?.((i)=>i.value == deliveryLocationType)?.text || ''}
        </span>
      );
    }
  };

  const TableCellCurrency = (rowData: any) => {
    const { blContainerId, containerNo,currency } = rowData.row.original;
    if (!disableFields) {
      return (
        <Form.Select
          disabled={disableFields}
          value={currency || ''}
          onChange={(e) => {
            dispatch(
              updateSelectedCCyOnContainer({
                containerNo,
                currency: e.target.value
              })
            );


          }}
          className='fs--1 form-control  form-select'>
        
            <option key={''} value={''}></option>
            {currencyList.map((option: any) => (
                <option key={option.currencyCode} value={option.currencyCode}>
                  {option.currencyShortName}
                </option>
            ))}
          
          
        </Form.Select>
      );
    } else {
      return (
        <span>
         
          {currency}
        </span>
      );
    }
  };

  
  /*   const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const dataCountry = useDataCountry();

  const rbac = useSelector((state: RootState) => state.userProfile);
  const extensionTypeOption = useSelector(
    (state: RootState) => state.overstay.extensionTypeOptions
  );

  const deleveryLocationList = useSelector(
    (state: RootState) => state.overstay.deleveryTypeLocation
  );

  const destuffingTpList = useSelector(
    (state: RootState) => state.pickup.destuffingTpList
  );
  const returnTypeList = useSelector(
    (state: RootState) => state.invoiceChange.returnTypeList
  );

  const pickUpForm = useSelector((state: RootState) => state.pickup.pickUpData);

  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );

  const selectedCustomerEmailId = useSelector(
    (state: RootState) => state.userProfile.profile.email
  );

  const recentId = useSelector((state: RootState) => {
    const arr = state?.documentHistory?.data?.filter(
      (inst) =>
        inst.instructionId == 816 &&
        (inst.event == 'Submitted') &&
        inst.docStatus !== 'Y' &&
        inst.docStatus !== 'N'
    );
    return arr.length <= 0
      ? null
      : arr.reduce((a: any, b: any) => (a.blEventId > b.blEventId ? a : b));
  });
  const linerId = useSelector((state:RootState)=>state.navbarTop.linerId)
  //const onSubmit = handleSubmit(async (payload) => {});

  //const [rejectRemarks, setRejectionRemarks] = useState('');
  const [userRemark, setUserRemark] = useState('');
  type FormValues = {
    // pickUpLoc: string;
    needExtensionFor: string;
    deliveryLocationType:string;
    plannedPickupDate: Date | null;
    userRemark: string;
    destuffingTp: string;
    returnLoc: string;
    rejectRemarks: string;
    status:string;
    customerCode:string;
    requesterEmail:string;
    ccy: string;

  };

  const validationSchema = Yup.object().shape({

    needExtensionFor: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:releaseType') })
    ),
    customerCode: (selectedLinerId == '1' && (dataCountry == 'KE' || dataCountry === 'UG')) || dataCountry == 'HK'? 
    Yup.string():
    Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:customerCode') })
    )
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });


  const disabledPath = [
    
    {
      path: '/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[3] !== 'instruction',
    },
    {
      path: 'instruction/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    {
      path: 'payment/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    { path: '/instruction/:blNum/:encrypt/:encryptdoc' },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const disableFields = isEdit?false:
    profileDetails?.partnerType === 'liner' ||
    (!!disabledPath && profileDetails?.partnerType !== 'liner');

  useEffect(() => {
    if(dataCountry)
    dispatch(extensionTypeList(dataCountry)).unwrap().then((data)=>{
      if(disabledPath)return
      dispatch(updateSelectedneedExtensionFor(data.value))
    })

    if(dataCountry && linerId){
      dispatch(deleveryLocTypeList({dataCountry, linerId}))
    }

    //dispatch(getDestuffingTypeList(country || profileDetails.partnerCountry));
   // dispatch(getReturnTypeList(country || profileDetails.partnerCountry));
    // const userSelectedCountry = [
    //   {
    //     loginId: profileDetails.email,
    //     country:
    //       country || linerDataCountry || profileDetails.partnerCountry || '',
    //   },
    // ];
    // window.localStorage.setItem(
    //   '@user-selected-datacountry',
    //   JSON.stringify(userSelectedCountry)
    // );
    i18next.changeLanguage(
      linerDataCountry || profileDetails.partnerCountry || ''
    );
  }, [dataCountry, linerId]);


  const onSubmit = handleSubmit(async (payload) => {
    const today = new Date();
    today.setHours(0,0,0,0)
    if(data.filter((item:any)=>item.needExtensionFor !=0).find((Item:any)=>Item.plannedPickupDate < today)){
        toast.error("Planned Pickup Date value can not be before current date")
        return
    }
    try {
      dispatch(
        saveExtensionRequest({
          ...payload,
          requestedPartnerId: profileDetails?.partnerId,
          blId: AESDecrypt(blNum),
          extensionRequestId:location.pathname.includes('bl')?recentId?.docId:extensionRequestId,
          containerDetails: data.filter((item:any)=> item.plannedPickupDate).map((item:any) => ({
            containerPickupId: null,
            cntNo:item.containerNo,
            pickupLocation:item.needExtensionFor,                  
            pickupDate:moment(item.plannedPickupDate).format('YYYY-MM-DDTHH:mm:ss') ,
            truckerName:"",
            deliveryLocationType:dataCountry=='KE' || dataCountry == 'UG'?item.deliveryLocationType:null,
            truckerId: "",
            currency: dataCountry=='KE' || dataCountry == 'UG' ? item.currency:null

          })),
          customerEmailId:profileDetails?.email,
          remarks:payload.rejectRemarks??"",
          customerCode:payload.customerCode,
          status:"S",
          extensionReason:extensionTypeOption?.find?.((i)=>i.value == extensionReasonData)?.text || ''
          
        })
      ).unwrap()
      .then((response: any) => {
        toast.success(response?.data?.responseMsg);
        setTimeout(()=>{
          dispatch(
            getInstructionList({
              blNum: AESDecrypt(blNum),
              country: dataCountry || /* profileDetails.partnerCountry || */ '',
            })
          );
          dispatch(updateDashbaordRefresh(true))
       },3500)
       onBack()
       
      });


      
    } catch (err: any) {
      console.error(err)
     }
  });

  useEffect(() => {
    if (disabledPath && selectedLinerId) {
         dispatch(getExtensionRequest({docId,
          selectedLinerId:
          profileDetails.partnerType === 'liner'
            ? profileDetails?.partnerId
            : selectedLinerId,
         })).unwrap().then
         ((r)=>{
         
          setValue('userRemark', r.data?.userRemarks??'')
          setValue('status', r.data?.status??'')
          setValue('rejectRemarks', r.data?.remarks??'')
          setValue('customerCode',r.data?.customerCode??'')
          setValue('requesterEmail',r.data?.customerEmailId??'')
         })
         
     }else{
      setValue('needExtensionFor', '0');
      setValue('plannedPickupDate', null)
      setValue('userRemark', '')
      setValue('destuffingTp', '1');
      setValue('returnLoc', '0');
      setValue('status', '');
      setValue('ccy','')
      setValue('rejectRemarks','')
      setValue('customerCode','')
      setValue('requesterEmail','')
      dispatch(clearData())
      resetForm()
      //dispatch(updateSelectedPlannedPickupDate(null))
    }
    setIsEdit(false)
     
  }, [docId,location.pathname, selectedLinerId]);

  useEffect(() => {
    if (!disabledPath || isEdit) {
    if (blNum && selectedLinerId)
      dispatch(
        getOBLForOverStay({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      ).unwrap().then(()=>{
        if(recentId)
        dispatch(populateExtensionRequest({
          docId:recentId?.docId,
          selectedLinerId:
          profileDetails.partnerType === 'liner'
            ? profileDetails?.partnerId
            : selectedLinerId,
      })).unwrap().then((payload:any)=>{
        setValue('userRemark',payload?.data?.userRemarks)
        setValue('customerCode',payload?.data?.customerCode)
      })
      })
    }
  }, [blNum, isEdit, selectedLinerId]);

  useEffect(()=>{
    dispatch(getCurrencyDDList())
  },[])

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      if (status === 'R' && (watch('rejectRemarks') === '' || watch('rejectRemarks') == null)) {
        toast.dark(t('resource_message:remarksMandatory'));
      }else {
        dispatch(
          commonApproveReject({
            instructionId: docId ? parseInt(docId) || null : null,
            instructionType: type || null,
            country:dataCountry,
            linerRemarks: watch('rejectRemarks') || null,
            customerRemarks: userRemark || null,
            status: status || null,
            customerCode:watch('customerCode') || null
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
            dispatch(updateDashbaordRefresh(true))
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        dispatch(
          getInstructionList({
            blNum: AESDecrypt(blNum),
            country: dataCountry || profileDetails.partnerCountry || '',
          })
        );
        onBack()
      }
    }
  };

  const resetForm = () => {
    setValue('needExtensionFor', '0');
    setValue('plannedPickupDate',null);
    setValue('ccy','');
    setValue('deliveryLocationType','0')
    setValue('userRemark','');
    setValue('customerCode','');
   
    dispatch(clearTableInputValues())

  };

  const contactDetailsColumns: IDataTableColumn[] = [
    {
      accessor: 'blContainerId',
      Header: 'blContainerId',
    },
    {
      accessor: 'containerNo',
      Header: t('resource_2:containerNo'),
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: 'needExtensionFor',
      Header: t('resource_3:needExtensionFor'),
      width: 220,
      maxWidth: 220,
      Cell: TableCellReturnLocation,
    },
    {
      accessor: 'IntendedDate',
      Header: t('resource_3:plannedPickupDate'),
      width: 220,
      maxWidth: 220,
      Cell:TableCell,
      //  columnFilterType: 'date-range',
    },
    dataCountry == 'KE' || dataCountry == 'UG' ?
    {
      accessor:'deleveryLocType',
      Header:t('resource_3:deleveryLocType'),
      width:220,
      maxWidth:220,
      Cell:TableCellDeleveryLocType,
    }:{
      accessor: 'deleveryLocType',
      Header: '',
      width: 0,
      maxWidth: 0,
    },
    {
      accessor: 'currency',
      Header: t('resource_1:currency'),
      width: 220,
      maxWidth: 220,
      Cell:TableCellCurrency,
      //  columnFilterType: 'date-range',
    }



  ];

  return (
    <Card style={{minHeight:'100%'}}>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={onBack}
        />
        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_3:overstayExtensionRequest')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {!disableFields?  <div className='col-lg-6 mb-3'>
                <Form.Group controlId='overStayExtension.needExtensionFor'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_5:needExtensionForAllContainer')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:pickUpLoc')}
                    {...register('needExtensionFor')}
                    className='fs--1'
                    disabled={disableFields}
                    value={`${watch('needExtensionFor')}`}
                    onChange={(e) => {
                      dispatch(updateSelectedneedExtensionFor(e.target.value))
                      setValue('needExtensionFor', e.target.value)
                    }}
                  >
                    {extensionTypeOption.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>:""}
           
              {!disableFields && (dataCountry === 'KE' || dataCountry == 'UG') ?  <div className='col-lg-6 mb-3'>
                <Form.Group controlId='overStayExtension.deliveryLocationType'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_5:deliveryLocationTypeForAllContainer')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:pickUpLoc')}
                    {...register('deliveryLocationType')}
                    className='fs--1'
                    disabled={disableFields}
                    value={`${watch('deliveryLocationType')}`}
                    onChange={(e) => {
                      dispatch(updateSelectedDeleveryLocType(e.target.value))
                      setValue('deliveryLocationType', e.target.value)
                    }}
                  >
                    {deleveryLocationList.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>:""}
              {!disableFields?
              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='carrierHaulage.IntendedDate'>
                  <Form.Label className='fs--1'>
                    {t('resource_5:plannedPickupDateForAllContainer')}{' '}
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      title='We will calculate the storage/demurrage up to this date.'
                      className='me-2'
                    />
                  </Form.Label>
                  <div className='releaseDate'>
                    <DatePicker
                      //  selected={selectedDate?.intended}
                      selected={watch('plannedPickupDate')}
                      onChange={(date: any) => {
                        dispatch(
                          updateSelectedPlannedPickupDate(date)
                        );
                        setValue('plannedPickupDate', date)
                      }}
                      
                      className='fs--2 form-control'
                      timeIntervals={5}
                      dateFormat={getDateFormat()}
                      isClearable={clearable}
                      popperProps={{
                        strategy: 'fixed',
                      }}
                      minDate={new Date()}
                      //disabled={DatePickDisabled}
                      // setValue={value}

                      disabled={disableFields}
                    />
                  </div>
                </Form.Group>
              </div>:""}
              {!disableFields && (dataCountry === 'KE' || dataCountry === 'UG') ?  <div className='col-lg-6 mb-3'>
                <Form.Group controlId='overStayExtension.deliveryLocationType'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_5:currencyForAllContainer')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:currency')}
                    {...register('ccy')}
                    className='fs--1'
                    disabled={disableFields}
                    value={`${watch('ccy')}`}
                    onChange={(e) => {
                      dispatch(updateSelectedCcy(e.target.value))
                      setValue('ccy', e.target.value)
                    }}
                  >
                  <option key={''} value={''}></option>
                    {currencyList.map((option: any) => (
                      <option key={option.currencyCode} value={option.currencyCode}>
                        {option.currencyShortName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>:""}
              <div
                className='col-lg-12 mb-2 demmurageTable'
                style={{ zIndex: 1 }}>
                {true ? (
                  <DataTable
                    data={data}
                    gridName='OVER_INTSRUCTION'
                    columns={contactDetailsColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releaseInstruction'
                    autoHeight={true}
                    staticHiddenCols={
                      (dataCountry =='KE' || dataCountry == 'UG')
                      ?['blContainerId']
                      :['blContainerId', 'currency']}

                  />
                ) : (
                  ''
                )}
                
              </div>
              <div className='col-lg-12 mb-2'>
              <div className='row'>
              
               {((dataCountry =='KE' || dataCountry == 'UG')&& selectedLinerId=='1') || dataCountry == 'HK'? 
              
             "": <div className='col-lg-4 mb-3'>
             <Form.Group controlId='invoiceChanges.customerCode'>
               <Form.Label className='fs--1'>
               {!disableFields?<span className='mandatory'>*</span>:""} {t('resource_2:customerCode')}&nbsp;
               </Form.Label>
               <Form.Control
                 {...register('customerCode')}
                 className={`fs--1 form-control ${
                   errors.customerCode ? 'is-invalid' : ''
                 }`}
                 type='text'
                 maxLength={200}
                 onChange={({target}) => {
                   setValue('customerCode',target.value);
                }}
                 value={watch('customerCode')}
                 disabled={disableFields}
               />
               <div className='invalid-feedback'>
                 {errors.customerCode?.message?.toString()}
               </div>
             </Form.Group>
           </div> }
              

            <div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.userRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:userRemark')}
                  </Form.Label>
                  <Form.Control
                    className={'fs--1 form-control'}
                    {...register('userRemark')}
                    as='textarea'
                    onChange={(e) => setValue('userRemark', e.target.value)}
                    maxLength={100}
                    value={watch('userRemark')}
                    disabled={
                      disableFields
                    }
                  />
                </Form.Group>
              </div>

              {profileDetails.partnerType === 'liner'?<div className='col-lg-4 mb-3'>
                <Form.Group controlId='invoiceChanges.requesterEmail'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:requesterEmail')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('requesterEmail')}
                    className={`fs--1 form-control ${
                      errors.requesterEmail ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={200}
                    onChange={({target}) => {
                      setValue('requesterEmail',target.value);
                   }}
                    value={watch('requesterEmail')}
                    disabled={true}
                  />
                  <div className='invalid-feedback'>
                    {errors.requesterEmail?.message?.toString()}
                  </div>
                </Form.Group>
              </div>:""}  
      
             
              {(disableFields) && (
                  <div className='col-lg-4 mb-3'>
                    <Form.Group controlId='pickUpInstruction.rejectionRemark'>
                      <Form.Label className='fs--1'>
                        {t('resource_1:rejectRemark')}
                      </Form.Label>
                      <Form.Control
                        className={'fs--1 form-control'}
                        {...register('rejectRemarks')}
                        as='textarea'
                        onChange={(e) => setValue('rejectRemarks',e.target.value)}
                        //maxLength={150}
                        value={watch('rejectRemarks')}
                        disabled={
                          pickUpForm?.status === 'Completed' ||
                            pickUpForm?.status === 'Rejected' ||
                            profileDetails?.partnerType === 'customer'
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </div>
                )}

              {disableFields && (
                <div className='col-lg-5 mb-3'>
                  <Form.Group controlId='invoiceChanges.status'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:status')}&nbsp;
                    </Form.Label>
                    <Form.Control
                      className={'fs--1 form-control'}
                      type='text'
                      value={watch('status')}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              )}
            </div>  
           </div> 
              <>
                { profileDetails?.partnerType === 'liner'
                ? extensionRequestStatus === 'Submitted' &&  rbac.rbacDetails.overStayApproveReject === 'Y' && (
                   <>
                        <div className='d-flex justify-content-center align-items-center'>
                          <Button
                            disabled={
                              extensionRequestStatus === 'Completed' ||
                              extensionRequestStatus === 'Rejected'
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('A', 'EXTENSION_REQUEST');
                            }}
                            variant='success'
                            className='me-2 approve'
                            size='sm'
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              className='me-2 approve'
                            />
                            {t('resource_1:approve')}
                          </Button>

                          <Button
                            variant='danger'
                            className='reject me-2'
                            size='sm'
                            disabled={
                              extensionRequestStatus === 'Completed' ||
                              extensionRequestStatus === 'Rejected'
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('R', 'EXTENSION_REQUEST');
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className='me-2 reject'
                            />
                            {t('resource_1:reject')}
                          </Button>
                          {rbac.rbacDetails.snoozeCUD === 'Y' && !location.pathname.includes('/bl')?<SnoozedToButton    blNum={AESDecrypt(blNum)} blEventId={blEventId_docInsDashboard}  /> :""  }
                        </div>
                      </>
                    
                  
                ) : (
                  ((!disabledPath &&
                   profileDetails?.partnerType === 'customer')||isEdit) && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        className='me-2'
                        variant='falcon-primary'
                        size='sm'
                        type='submit'
                          disabled={
                            (()=>{ 
                            if(dataCountry =='ZA' || dataCountry == 'JO' || dataCountry == 'HK') return (data?.
                              filter((item:any)=>item.plannedPickupDate).length<1 || data.findIndex((i:any)=>i.needExtensionFor==0 != !i.plannedPickupDate)!=-1)
                            
                            if(dataCountry == 'KE' || dataCountry === 'UG')
                            {
                              const submitted = data?.filter((item:any)=> item.needExtensionFor != 0)
                              if (submitted.length < 1) return true
                              if (submitted.some((item:any)=>!item.plannedPickupDate 
                              || !item.deliveryLocationType || item.deliveryLocationType == '0' || item.currency == '')
                              ) return true

                              if(watch('customerCode')?.trim() == '' && linerId != '1') return true

                              return false
                            }
                            return true
                          
                          })()
                        }
                      >
                        <FontAwesomeIcon icon={faSave} className='me-2' />
                        {t('resource_1:submit')}
                      </Button>

                     <Button
                        className='me-2'
                        variant='falcon-danger'
                        size='sm'
                        onClick={resetForm}
                      >
                        <FontAwesomeIcon icon={faRedo} className='me-2' />
                        {t('resource_1:reset')}
                      </Button>
                    </div>
                  )
                )}
              </>
              <div className='d-flex justify-content-center align-items-center'>
                  {extensionRequestStatus ==='Submitted'&& disableFields &&  profileDetails?.partnerType === 'customer'&&<Button
                    variant='falcon-primary'
                    size='sm'
                    className='me-2'
                    onClick={() => {
                      setIsEdit(true);
                    }}>
                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                    {t('resource_1:edit')}
                  </Button>}
                </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OverstayExtensionRequest;
