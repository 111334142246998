import SoftBadge from '../../../../components/common/SoftBadge';


// export const getBadges = (instruction: string | null) => {
//   const status = Object.freeze({
//     requested: t('resource_1:requested').toString(),
//     cancelled: t('resource_1:cancelled').toString(),
//     exRequested: t('resource_2:exRequested').toString(),
//     pmtInitiated: t('resource_2:pmtInitiated').toString(),
//     submitted: t('resource_1:submitted').toString(),
//     paid: t('resource_1:paid').toString(),
//     received: t('resource_1:received').toString(),
//     completed: t('resource_1:completed').toString(),
//     approved: t('resource_1:approved').toString(),
//     exReleased: t('resource_2:exReleased').toString(),
//     released: t('resource_1:released').toString(),
//     draft: t('resource_1:draft').toString(),
//     docPending: t('resource_2:docPending').toString(),
//     overdue: t('resource_1:overdue').toString(),
//     due: t('resource_1:due').toString(),
//     inWork: t('resource_2:inWork').toString(),
//     open: t('resource_1:open').toString(),
//     rejected: t('resource_1:rejected').toString(),
//     partial: t('resource_1:partial').toString(),
//     referPortSite: t('resource_1:referportsite').toString(),
//     paidFromSAP: t('resource_1:Paid_From_SAP').toString(),
//     SELF: t('resource_1:SELF').toString(),
//     UNASSIGNED: t('resource_1:UNASSIGNED').toString(),
//     OTHER: t('resource_1:OTHER').toString(),
//     YES: t('resource_1:yes').toString(),
//     NO: t('resource_1:no').toString(),
//     LEVELONE:t('resource_3:approvedByL1').toString(),
//     LEVELTWO:t('resource_3:approvedByL2').toString(),
//     LEVELTHREE:t('resource_3:approvedByL3').toString(),
//     LEVELFOUR:t('resource_3:approvedByL4').toString(),
//     LEVELFIVE:t('resource_3:approvedByL5').toString(),
// });
 
//   if (
//     instruction?.toUpperCase() === status.requested.toLowerCase() ||
//     instruction?.toUpperCase() === status.exRequested.toUpperCase() ||
//     instruction?.toUpperCase() === status.pmtInitiated.toUpperCase() ||
//     instruction?.toUpperCase() === status.submitted.toUpperCase() ||
//     instruction?.toUpperCase() === status.partial.toUpperCase() ||  
//     instruction?.toUpperCase() === status.LEVELONE.toUpperCase() ||
//     instruction?.toUpperCase() === status.LEVELTWO.toUpperCase() ||
//     instruction?.toUpperCase() === status.LEVELTHREE.toUpperCase() ||
//     instruction?.toUpperCase() === status.LEVELFOUR.toUpperCase() ||
//     instruction?.toUpperCase() === status.LEVELFIVE.toUpperCase()

//   ) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faPlus} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.completed.toUpperCase()||
//               instruction?.toUpperCase() === status.referPortSite.toUpperCase() ||
//               instruction?.toUpperCase() === status.paidFromSAP.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.approved.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.rejected.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='danger' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.cancelled.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.paid.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-blue-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faCreditCard} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.received) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faFolder} /> */}
//       </SoftBadge>
//     );
//   } else if (
//     instruction === status.released ||
//     instruction === status.exReleased
//   ) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheckCircle} /> */}
//       </SoftBadge>
//     );
//   } else if (
//     instruction === status.docPending 
//   ) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faBars} /> */}
//       </SoftBadge>
//     );
//   } else if(instruction === 'Draft'
// ) {
//   return (
//     <SoftBadge pill bg='warning' className='me-2'>
//       {"Not Submitted"}
//       {/* &nbsp; <FontAwesomeIcon icon={faBars} /> */}
//     </SoftBadge>
//   );
// } else if (instruction === status.inWork) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faSave} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.overdue) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.overdue) {
//     return (
//       <SoftBadge pill bg='danger' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.due) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.open) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faPlus} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.SELF.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.UNASSIGNED.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.OTHER.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   }else if (instruction?.toUpperCase() === status.YES.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.NO.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   }  else {
//     return '';
//   }
// };

export const getBadges = (instruction: string | null) => {
switch(instruction){
  case "Draft":
    instruction = "NOT SUBMITTED"
    break;

}
const styleMap:any = ({
  REQUESTED: 1,
  CANCELLED: 1,
  "EX REQUESTED": 1,
  "PMT INITIATED": 1,
  SUBMITTED: 1,
  "APPROVED BY L1":1,
  "APPROVED BY L2":1,
  "APPROVED BY L3":1,
  "APPROVED BY L4":1,
  "APPROVED BY L5":1,
  OTHER: 1,
  PARTIAL: 1,
  NO: 1,
  COMPLETED: 2,
  APPROVED: 2,
  "AVAILABLE AT TERMINAL WEBSITE": 2,
  PAID_FROM_SAP: 2,
  SELF: 2,
  REJECTED: 3,
  PAID: 4,
  RECEIVED: 5,
  UNASSIGNED: 5,
  YES: 5,
  "EX RELEASED": 5,
  RELEASED: 5,
  DUE: 5,
  "NOT SUBMITTED": 6,
  "DOC. PENDING": 6,
  OVERDUE: 6,
  "IN WORK": 6,
  OPEN: 7,
  "Draft":6
});

switch(styleMap[instruction?.toUpperCase()??'']){
  case (1):
    return <SoftBadge pill bg='primary' className='me-2'>{instruction?.toUpperCase()}</SoftBadge>
  case (2):
    return <SoftBadge pill bg='success' className='me-2'>{instruction?.toUpperCase()}</SoftBadge>
  case (3):
    return <SoftBadge pill bg='danger' className='me-2'>{instruction?.toUpperCase()}</SoftBadge>
  case (4):
    return <SoftBadge pill bg='dark' className='me-2 text-blue-1'>{instruction?.toUpperCase()}</SoftBadge>
  case (5):
    return <SoftBadge pill bg='dark' className='me-2 text-green-1'>{instruction?.toUpperCase()}</SoftBadge>
  case (6):
    return <SoftBadge pill bg='warning' className='me-2'>{instruction?.toUpperCase()}</SoftBadge>
  case (7):
    return <SoftBadge pill bg='primary' className='me-2 text-green-1'>{instruction?.toUpperCase()}</SoftBadge>
  default: 
    return <SoftBadge pill bg='primary' className='me-2'>{instruction?.toUpperCase()}</SoftBadge>
  }
};