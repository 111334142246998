import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import {
  faTimes,
  faSave,
  faRedo,
  faLocation,
  faInfoCircle,
  faBackwardStep,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggleAddAgent, toggleNominationReq } from '../slices/dashboardSlice';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ChangeEvent, ChangeEventHandler, useState, useRef } from 'react';
import { AppDispatch, RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateSelectedTab } from '../../nominationReq/slices/nominationReq';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { enableNominateAgent } from '../../standingInstruction/slices/standingInstructionTable';
import FileUpload from '../../blPayment/FileUpload';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncDebounce } from 'react-table';
import { fetchAddressSuggestions2 } from '../actions/instruction';
import { getCountryList } from '../../registration/actions/Registration';
import { updateSelectedAddressResult } from '../../blRequestInstruction/components/instructionSlice/CarrierHaulageSlice';
import { InstructionList } from '../types';
import React, { useEffect } from 'react';
import {
  addAgentNnominate,
  getAgentNnominate,
  getCodeList,
  addMultiAgentNnominate,
} from '../../blRequestInstruction/components/actions/Instruction';
import { getInstructionList } from '../actions/instruction';
import { resolve } from 'path';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCompanyDetailsForAgentDelegation } from '../actions/instruction';
import { Root } from 'react-dom/client';
import {
  clearFields,
  toggleDisableFields,
  toggleGrantFrtInvAccess,
  toggleBlAccess,
  toggleTimePendingInvoiceAccess,
  updateAgentAccessList,
} from '../../blRequestInstruction/components/instructionSlice/AgentNominationSlice';
import { AESDecrypt, AESEncrypt } from '../../../../encrypt-util';
import SoftBadge from '../../../../components/common/SoftBadge';
import {
  faArrowAltCircleLeft,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import InputMask from 'react-input-mask';
import AgentDelegationTNC from './agentDelegationTnc';
import { commonApproveReject } from '../actions/instruction';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DataTable from '../../../common/components/DataTable';
import { getUnassignedBlByBlNo } from '../actions/dashboard';
import axiosInstance from '../../../../axiosSetup';
import UploadIndicator from '../../../../components/common/UploadIndicator';
import { getAppConfig } from '../../../common/actions/userDetails';
import { getLOIAttConf } from '../../blRequestInstruction/components/actions/Instruction';


const ConsigneeNominateAgent = () => {
  const data = useSelector((state: RootState) => state.agentDelegation.data);
  const blList = useSelector((state: RootState) => state.dashboard.data);
  const { encrypt, encryptdoc } = useParams();
  const [loadingFileState, setLoadingFileState] = useState(0)
  const invTypeList = useSelector(
    (state: RootState) => state.agentDelegation.invTypeList
  );

  const agentAccessList = useSelector(
    (state: RootState) => state.agentDelegation.agentAccessList
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  let selectedTab = useSelector(
    (state: RootState) => state.nominationReq.selectedTab
  );

  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );

  const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  //const docId = location.pathname.split('/')[4];
  const docId = AESDecrypt(encryptdoc);

  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const [taxId, setTaxId] = useState('');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState(
    profileDetails?.partnerName || ''
  );
  const [email, setEmail] = useState(profileDetails?.email || '');
  const [partyName, setPartyName] = useState('');
  const [blPartyId, setBlPartyId] = useState('');
  const [partyEmail, setPartyEmail] = useState('');
  const [pod, setPod] = useState('');
  const [pol, setPol] = useState('');
  const [selectedFileServerPath, setSelectedFileServerPath] = useState<Array<string|null>>([null,null])

  const [loiUpload, setLoiUpload] = useState('');

  const [agentPartnerId, setAgentPartnerId] = useState('');
  const [allowFreightInvoice, setAllowFreightInvoice] = useState('');
  const [allowBlAccess, setAllowBlAccess] = useState('');
  const [allowTimePendingInvoice, setAllowTimePendingInvoice] = useState('');
  const [agentNominationId, setAgentNominationId] = useState(0);
  const [status, setStatus] = useState('');
  const [taxIdFormat, setTaxIdFormat] = useState('');
  const [requestType, setRequestType] = useState('');

  const [blId, setBlId] = useState<any>('');
  const [blNum, setBlNum] = useState<any>('');
  const [attachedFileSize, setAttachedFileSize] = useState(0)
  //all instruction List
  const instructionList = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const [state, setState] = useState<any>({
    taxId: '',
    companyName: '',
    partyName: '',
    blPartyId: '',
    partyEmail: '',
    emailId: '',
    fileName: '',
    address: '',
    agentPartnerId: '',
    allowFreightInvoice: '',
    agentNominationId: '',
    status: '',
    allowTimePendingInvoice: '',
    allowBlAccess: '',
    customerRemarks: '',
    rejectRemark: '',
    requestType: '812',
  });

  const matchPth =
    matchPath(
      {
        path: '/bl/:blNum/:encrypt/:encryptdoc',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: '/bl/:blNum/:encrypt/:encryptdoc',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );

  useEffect(() => {}, [blNum]);

  useEffect(() => {
    dispatch(getCountryList(1))
      .unwrap()
      .then((response: any) => {
        response.map((option: any) => {
          if (option.countryShortCode === profileDetails.partnerCountry) {
            setTaxIdFormat(option.taxIdFormat);
          }
        });
      });
  }, []);

  const isGrantFrtInvAccess = useSelector(
    (state: RootState) => state.agentDelegation.isGrantFrtInvAccess
  );

  const isBlAccess = useSelector(
    (state: RootState) => state.agentDelegation.isBlAccess
  );

  const isTimePendingInvoiceAccess = useSelector(
    (state: RootState) => state.agentDelegation.isTimePendingInvoiceAccess
  );
  // useEffect(() => {
  //   isGrantFrtInvAccess? setAllowFreightInvoice('Y') : setAllowFreightInvoice('N');
  // })

  const [termsAccepted, setTermsAccepted] = useState(false);

  const onToggleGrantFrtInvAccess = () => {
    dispatch(toggleGrantFrtInvAccess(!isGrantFrtInvAccess));
  };
  const onToggleBlAccess = () => {
    dispatch(toggleBlAccess(!isBlAccess));
  };
  const onToggleTimePendingInvoiceAccess = () => {
    dispatch(toggleTimePendingInvoiceAccess(!isTimePendingInvoiceAccess));
  };

  const selectedAddressResult = useSelector(
    (state: RootState) => state.carrierHaulage.selectedAddressResult
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.carrierHaulage.isAddressSuggesstionsLoading
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.carrierHaulage.registrationAddressSuggesstion
  );

  const addr = useSelector((state: RootState) => state.agentDelegation.address);
  const compName = useSelector(
    (state: RootState) => state.agentDelegation.companyName
  );
  const emailId = useSelector(
    (state: RootState) => state.agentDelegation.emailId
  );
  const partnerId = useSelector(
    (state: RootState) => state.agentDelegation.agentPartnerId
  );

  const loiConfig = useSelector((state:RootState)=>state.agentDelegation.loiConfig)

  const isMandatory = loiConfig[0]?.isMandatory
  

  const selectedDataNominatedAgent = data?.list as any;

  
  useEffect(()=>{
    if(linerId && dataCountry)
    dispatch(getLOIAttConf({country:dataCountry, liner:linerId,InstId:state.requestType}))
  },[dataCountry, linerId,state.requestType]) 
  
  useEffect(() => {
    dispatch(getAppConfig('attachment.maxsize'))
      .unwrap()
      .then((response: any) => {
        if (response !== '') {
          setAttachedFileSize(+response.response.data);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(
      toggleGrantFrtInvAccess(allowFreightInvoice == 'Y' ? true : false)
    );
  }, [allowFreightInvoice]);
  useEffect(() => {
    dispatch(toggleBlAccess(allowBlAccess == 'Y' ? true : false));
  }, [allowBlAccess]);
  useEffect(() => {
    dispatch(
      toggleTimePendingInvoiceAccess(
        allowTimePendingInvoice == 'Y' ? true : false
      )
    );
  }, [allowTimePendingInvoice]);
  const resetForm = () => {
    setTaxId('');
    setAddress('');
    setCompanyName('');
    setEmail('');
    setPartyName('');
    setBlPartyId('');
    setPartyEmail('');
    setLoiUpload('');
  };
  const formReset = () => {
    setState({
      //  blId: AESDecrypt(blNum),

      taxId: '',
      companyName: '',
      emailId: '',
      partyName: '',
      blPartId: '',
      partyEmail: '',
      fileName: '',
      address: '',
      agentPartnerId: '',
      allowFreightInvoice: '',
      agentNominationId: '',
      status: '',
      allowTimePendingInvoice: '',
      allowBlAccess: '',
      customerRemarks: '',
      rejectRemark: '',
      requestType: '812',
    });
  };

  useEffect(() => {
    dispatch(
      getCodeList({
        codeType: 'INV_TYPE',
        linerId: linerId,
        partnerCountry:
          profileDetails.partnerType === 'liner'
            ? dataCountry || 'US'
            : profileDetails?.partnerCountry || 'US',
      })
    );
  }, [location]);

  const [selectedAccess, setSelectedAccess] = useState<any>({});

  useEffect(() => {
    //for new instruction set type
    if (invTypeList.length > 0) {
      if (location.pathname.split('/')[1] === 'instruction') {
        dispatch(
          updateAgentAccessList(
            invTypeList.map((v: any) => {
              return {
                agentAccessId: null,
                agentNominationId: null,
                type: v?.value,
                allowAccess: 'Y',
              };
            })
          )
        );
      }
    }
  }, [invTypeList]);

  const matchPathInstruction = () =>
    matchPath(
      {
        path: '/instruction/812',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: '/instruction/813',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );

  const disableFields = useSelector(
    (state: RootState) => state.agentDelegation.disableFields
  );
  useEffect(() => {
    dispatch(
      toggleDisableFields(
        profileDetails?.partnerType === 'liner' ||
          (!!matchPth && profileDetails?.partnerType !== 'liner')
      )
    );
    // setDisableFields(
    //   profileDetails?.partnerType === 'liner' ||
    //   (!!matchPth && profileDetails?.partnerType !== 'liner'));
  }, []);

  const _onCloseForm = () => {
    dispatch(toggleDocumentHistoryColumnShort(false));
    navigate('/instruction');
  };

  type FormValues = {
    taxId: string;
    address: string;
    companyName: string;
    email: string;
    partyName: string;
    blPartyId: any;
    partyEmail: string;
    contactNo: string;
    loiUpload: any;
    byteArr: string;
    agentPartnerId: any;
    agentNominationId: any;
    allowFreightInvoice: any;
    status: string;
    allowTimePendingInvoice: string;
    allowBlAccess: string;
    blNum: string;
    requestType: string;
    fileName: string;
  };

  const validationSchema = Yup.object().shape({
    // taxId: Yup.string()
    //   .required('Tax ID is required')
    //   .when('country', {
    //     is: 'USA',
    //     then: Yup.string().matches(
    //       /^\d\d-\d{7}$/,
    //       'Invalid input format. It should match the pattern "DD-#######"'
    //     ),
    //   }),
    // address: Yup.string().required(
    //   t('resource_3:isRequired', { field: t('resource_1:address') })
    // ),
    // companyName: Yup.string().required(
    //   t('resource_3:isRequired', { field: t('resource_2:companyName') })
    // ),
    // email: Yup.string()
    //   .email(t('resource_3:isInvalid', { field: t('resource_1:email') }))
    //   .required(t('resource_3:isRequired', { field: t('resource_1:email') })),
    // byteArr: Yup.string().required('Upload LOI'),

    blNum: Yup.string()
      .required('BL Number is required')
      .test(
        'blNum',
        'Invalid BL Number',
        (val) => !(blId === '' || blId === null || blId === undefined)
      ),
    //contactNo: Yup.string().required(' Contact No is required'),
    //loiUpload: Yup.string().required('Upload LOI '),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(
    async (payload: any) => {
      try {
        dispatch(
          addMultiAgentNnominate({
            ...payload,
            blId: blId !== '' ? blId.toString() || null : null,
            // byteArr: byteArr,
            // fileName: fileName,
            serverPath:selectedFileServerPath[0],
            tempFileName: selectedFileServerPath[1],
            requestedPartnerId: blPartyId,
            timePendingInvoice: isTimePendingInvoiceAccess ? 'Y' : 'N',
            blAccess: isBlAccess ? 'Y' : 'N',
            allowFreightInvoice: allowFreightInvoice ? 'Y' : 'N',
            agentType: 'agent',
            customerRemarks: state?.customerRemarks || null,
            accessList: agentAccessList.map((v: any) => {
              return {
                ...v,
                requestAccess: v.allowAccess || null,
              };
            }),
            agentPartnerId: profileDetails?.partnerId,
            companyName: profileDetails?.partnerName,
            email: profileDetails?.email,
            partyName: partyName,
            partyEmail: partyEmail,
            status: 'Submitted',
            agentNominationId: '0',
            requestType: requestType || '812',
            isMultiReq: true,
          })
        )
          .unwrap()
          .then((v: any) => {
            if (v?.data) {
              if (v?.data.length > 0) {
                if (
                  v.data
                    .map((x: any) => x.message !== 'Data Saved Successfully')
                    .includes(true)
                ) {
                  v.data.map((x: any) => {
                    //failed
                    toast.dark(x.message);
                  });
                } else {
                  //success
                  toast.dark(t('resource_2:successfullyCreated'));
                }
              }
            } else {
              //exception
              toast.dark('Please try again !');
            }
          });
        //toast.dark(t('resource_2:successfullyCreated'));
        reset();
        navigate('/instruction');
      } catch (err: any) {
        console.error(err.message);
      }
    },
    (error) => {
      console.error('Handle submit error: ', error);
    }
  );

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      if (
        status === 'R' &&
        (state?.rejectRemark === '' || state?.rejectRemark == null)
      ) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else {
        dispatch(
          commonApproveReject({
            instructionId: docId ? parseInt(docId) || null : null,
            instructionType: type || null,
            linerRemarks: state?.rejectRemark || null,
            country:dataCountry,
            customerRemarks: state?.customerRemarks || null,
            status: status || null,
            emailTo: selectedDataNominatedAgent?.agentEmailId || null,
            requestedPartnerId:
              selectedDataNominatedAgent?.requestedPartnerId || null,
            blId: selectedDataNominatedAgent?.blId || null,
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        navigate('/instruction');
      }
    }
  };

  const _onAddressChange = useAsyncDebounce((value: string) => {
    var countryCode = 'USA';
    if (profileDetails?.partnerCountry === 'US') {
      countryCode = 'USA';
    } else if (profileDetails?.partnerCountry === 'SG') {
      countryCode = 'SGP';
    } else if (
      profileDetails?.partnerCountry === 'ZA' ||
      profileDetails?.partnerCountry === 'ZAF'
    ) {
      countryCode = 'ZAF';
    }else if (profileDetails?.partnerCountry === 'KE') {
      countryCode = 'KEN';
    }else if(profileDetails.partnerCountry == 'JO'){
      countryCode = 'JOR'
    }
    dispatch(
      fetchAddressSuggestions2({
        text: value,
        country: countryCode,
      })
    );
  }, 200);

  useEffect(() => {
    if (taxId !== '' /*  && partnerId */ && !disableFields) {
      addr !== '' ? setAddress(addr) : setAddress('');
      compName !== '' ? setCompanyName(compName) : setCompanyName('');
      emailId !== '' ? setEmail(emailId) : setEmail('');
      partyName !== '' ? setPartyName(partyName) : setPartyName('');
      blPartyId !== '' ? setBlPartyId(blPartyId) : setBlPartyId('');
      partyEmail !== '' ? setPartyEmail(partyEmail) : setPartyEmail('');
      partnerId !== '' ? setAgentPartnerId(partnerId) : setAgentPartnerId('');
      setValue('companyName', compName ? compName : '');
      setValue('email', emailId ? emailId : '');

      setValue('partyName', partyName ? partyName : '');
      setValue('blPartyId', blPartyId ? blPartyId : '');
      setValue('partyEmail', partyEmail ? partyEmail : '');

      setValue('address', addr ? addr : '');
      setValue('agentPartnerId', partnerId ? partnerId : '');
    } else if (taxId && !partnerId && !agentPartnerId) {
      setAddress('');
      setCompanyName('');
      setEmail('');
      setPartyName('');
      setBlPartyId('');
      setPartyEmail('');
      setAgentPartnerId('');
      setValue('companyName', '');
      setValue('email', '');
      setValue('partyName', '');
      setValue('blPartyId', '');
      setValue('partyEmail', '');
      setValue('address', '');
      setValue('agentPartnerId', '');
    }
    // } else if(!agentPartnerId && taxId !== '') {
    //     toast.error('Entered partner is not available on the ODeX, Kindly request Partner to get registered on the ODeX');
    // }
  }, [taxId, addr, compName, emailId, partnerId]);

  const rbac = useSelector((state: RootState) => state.userProfile);

  const [tncDialog, setShowTncDialog] = useState(false);

  const closeTncDialog = () => setShowTncDialog(false);
  const showTncDialog = () => setShowTncDialog(true);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file.size > attachedFileSize) {
        toast.error(
          `File size is greater than ${Math.round(attachedFileSize/1000000)}MB. Please upload file below ${Math.round(attachedFileSize/1000000)}MB`
        );
        e.target.value = '';
        return;
      }
      let fileExt = file.name.slice(-4);

      if (fileExt.toLowerCase() != '.pdf') {
        toast.error('Please upload Only PDF File');
        e.target.value = '';
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      
      try{
        setSelectedFileServerPath([null, null])
        setLoadingFileState(0)
        axiosInstance
        .post('/api/edo-rest-v1/v1/view/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total??1));
            setLoadingFileState(percentCompleted)
          },
        })
        .then((response) => {
          setLoadingFileState(100)
          setSelectedFileServerPath([response.data.path, response.data.tempFileName])
        }).catch(()=>{
          setLoadingFileState(-1)
        });
      }catch(e){
        console.error('File Upload Error: ', e);
      }
      setValue('fileName',file.name);
    }
  };

  const isAccess = (rowData: any) => {
    const { type, allowAccess } = rowData.row.original;
    const name = 'isAccess' + type.toString();
    return (
      <div className='row'>
        <input
          type='checkbox'
          id={name}
          name={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch(
              updateAgentAccessList(
                agentAccessList.map((x: any) => {
                  if (x.type === type) {
                    return {
                      ...x,
                      allowAccess: e?.target?.checked ? 'Y' : 'N',
                    };
                  } else {
                    return x;
                  }
                })
              )
            );
          }}
          checked={allowAccess && allowAccess === 'Y' ? true : false}
          disabled={disableFields}
        />
      </div>
    );
  };

  const blAccessColumns: IDataTableColumn[] = [
    {
      accessor: 'agentAccessId',
      Header: 'agentAccessId',
    },
    {
      accessor: 'agentNominationId',
      Header: 'agentNominationId',
    },
    {
      accessor: 'type',
      Header: 'Grant / Invoice Access',
      Cell: (rowData: any) => {
        const { type } = rowData.row.original;
        return invTypeList?.find((v: any) => v.value === type)?.text;
      },
      width: 300,
      maxWidth: 300,
    },
    {
      accessor: 'allowAccess',
      Header: '',
      width: 200,
      Cell: isAccess,
      maxWidth: 150,
    },
  ];
  const [desisionFocus, setDecisionFocus] = useState(false);
  const modalCloseFocus = useRef<any>(null);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  useEffect(() => {
    if (desisionFocus)
      if (modalCloseFocus.current) {
        setTimeout(() => {
          if (modalCloseFocus.current) modalCloseFocus.current.focus();
        }, 500);
        // modalCloseFocus.current.focus();
      }
  }, [desisionFocus]);

  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Header className='border-bottom d-flex justify-content-between align-items-center sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1'
          onClick={_onCloseForm}
        />
        {selectedTab === '' ? (
          <div className='row col-12'>
            <div className='col-lg-12'>
              <h2 className='fs-0 text-primary'>
                {' '}
                {requestType === '813'
                  ? t('resource_4:agent_nomination_to_shipper')
                  : requestType === '812'
                  ? t('resource_4:agent_nomination_to_consignee')
                  : t('resource_4:agent_nomination')}
              </h2>
            </div>
            {/*<div className='col-lg-3'>
              {agentNominationId ? (
                <span>
                  <SoftBadge
                    bg={status === 'ACTIVE' ? 'success' : 'dark'}
                    className='mb-2'>
                    <h1 className='fs--1 m-0'>
                      {status === 'ACTIVE'
                        ? t('resource_1:active')
                        : t('resource_1:inActive')}
                    </h1>
                  </SoftBadge>
                </span>
              ) : (
                <></>
              )}
              </div> */}
          </div>
        ) : (
          ''
        )}
        {!matchPth && selectedTab != '' ? (
          <>
            {t('resource_2:delegationRequest')} -{' '}
            {selectedTab === 'consignee' ? 'Consignee' : 'Liner'}
          </>
        ) : (
          ''
        )}
      </Card.Header>

      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:agentDelegationMessage') != '' && (
                <div className='col-lg-12 mb-1'>
                  <Form.Label style={{ color: '#f5822a' }}>
                    {t('resource_message:agentDelegationMessage')}
                  </Form.Label>
                </div>
              )}

              {/*  <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.requestTo'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_2:requestTo')}</Form.Label>
                  <div>
                    <Form.Check
                      type='radio'
                      name='requestTo'
                      id='requestToLiner'
                      label='Consignee'
                      checked={requestTo === 'customer' ? true : false}
                      className={'fs--1 form-check form-check-inline'}
                      onClick={(e) => {
                        setRequestTo('customer');
                      }}
                    />
                    <Form.Check
                      type='radio'
                      name='requestTo'
                      id='requestToLiner'
                      label='Carrier'
                      checked={requestTo === 'liner' ? true : false}
                      onClick={(e) => {
                        setRequestTo('liner');
                      }}
                      className={'fs--1 form-check form-check-inline'}
                    />
                  </div>
                </Form.Group>
              </div> */}

              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.registeredEmail'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{'BL Number'}</Form.Label>
                  <Form.Control
                    size='sm'
                    {...register('blNum')}
                    className={`fs--1 form-control form-control-sm ${
                      errors.blNum ? 'is-invalid' : ''
                    }`}
                    type='text'
                    maxLength={20}
                    onChange={(e) => {
                      const upperCaseBLNumValue = e.target.value.trim().toUpperCase();
                      setValue('blNum', upperCaseBLNumValue);
                      setBlNum(upperCaseBLNumValue);
                      // setValue('blNum', e.target.value.trim());
                      // setBlNum(e.target.value);
                      setBlId('');
                    }}
                    onBlur={(e) => {
                      if (e.target.value !== '') {
                        dispatch(
                          getUnassignedBlByBlNo({
                            blNo: blNum,
                            type: 'customer',
                          })
                        )
                          .unwrap()
                          .then((v: any) => {
                            if (v !== undefined && v?.id !== undefined) {
                              if (v?.id === '') {
                                toast.dark(
                                  'Delegation already exists for ' + blNum
                                );
                              } else {
                                if (
                                  location.pathname
                                    .split('/')
                                    .includes('812') &&
                                  v?.blPartyType === 'Shipper'
                                ) {
                                  if (v?.partyEmail && v?.partyEmail != '') {
                                    setBlId(v?.id || '');
                                    setPartyName(v?.partyName || '');
                                    setPartyEmail(v?.partyEmail || '');
                                    setBlPartyId(v?.blPartyId || '');
                                    setPod(v?.pod.substring(0, 2) || '');
                                    setPol(v?.pol.substring(0, 2) || '');
                                    if (
                                      v?.blPartyType &&
                                      v?.blPartyType != ''
                                    ) {
                                      setRequestType('813');
                                    }
                                    navigate(
                                      `/instruction/${AESEncrypt('813')}`
                                    );
                                  } else {
                                    setBlId('');
                                    setPartyName('');
                                    setPartyEmail('');
                                    setBlPartyId('');
                                    setPod('');
                                    setPol('');
                                    toast.dark('User not registered on ODeX');
                                  }
                                } else if (
                                  location.pathname
                                    .split('/')
                                    .includes('813') &&
                                  v?.blPartyType === 'Consignee'
                                ) {
                                  if (v?.partyEmail && v?.partyEmail != '') {
                                    setBlId(v?.id || '');
                                    setPartyName(v?.partyName || '');
                                    setPartyEmail(v?.partyEmail || '');
                                    setBlPartyId(v?.blPartyId || '');
                                    setPod(v?.pod.substring(0, 2) || '');
                                    setPol(v?.pol.substring(0, 2) || '');
                                    if (
                                      v?.blPartyType &&
                                      v?.blPartyType != ''
                                    ) {
                                      setRequestType('812');
                                    }
                                    navigate(
                                      `/instruction/${AESEncrypt('812')}`
                                    );
                                  } else {
                                    setBlId('');
                                    setPartyName('');
                                    setPartyEmail('');
                                    setBlPartyId('');
                                    setPod('');
                                    setPol('');
                                    toast.dark('User not registered on ODeX');
                                  }
                                } else if (
                                  v?.blPartyId === undefined ||
                                  v?.blPartyType === undefined ||
                                  v?.blPartyType === ''
                                ) {
                                  setBlId('');
                                  setPartyName('');
                                  setPartyEmail('');
                                  setBlPartyId('');
                                  setPod('');
                                  setPol('');
                                  toast.dark('BL not available');
                                } else {
                                  setBlId(v?.id || '');
                                  setPartyName(v?.partyName || '');
                                  setPartyEmail(v?.partyEmail || '');
                                  setBlPartyId(v?.blPartyId || '');
                                  setPod(v?.pod.substring(0, 2) || '');
                                  setPol(v?.pol.substring(0, 2) || '');
                                  if (
                                    v?.blPartyType &&
                                    v?.blPartyType != '' &&
                                    v?.blPartyType === 'Consignee'
                                  ) {
                                    setRequestType('812');
                                    navigate(
                                      `/instruction/${AESEncrypt('812')}`
                                    );
                                  } else if (
                                    v?.blPartyType &&
                                    v?.blPartyType != '' &&
                                    v?.blPartyType === 'Shipper'
                                  ) {
                                    setRequestType('813');
                                    navigate(
                                      `/instruction/${AESEncrypt('813')}`
                                    );
                                  }
                                }
                              }
                            } else {
                              setBlId('');
                              setPartyName('');
                              setPartyEmail('');
                              setBlPartyId('');
                              setPod('');
                              setPol('');
                              toast.dark('BL not available');
                            }
                          });
                      } else {
                        setBlId('');
                        setPartyName('');
                        setPartyEmail('');
                        setBlPartyId('');
                        setPod('');
                        setPol('');
                      }
                    }}
                    disabled={disableFields}
                    value={blNum}
                  />
                  <div className='invalid-feedback'>
                    {errors.blNum?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              {/* <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.agentPartnerId'>
                  <Form.Control
                    type='hidden'
                    value={agentPartnerId}
                    {...register('agentPartnerId')}></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentNominationId'>
                  <Form.Control
                    type='hidden'
                    value={agentNominationId}
                    {...register('agentNominationId')}></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentTaxId'>
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_2:taxId')}
                  </Form.Label>{' '}
                  <InputMask
                    disabled={disableFields}
                    {...register('taxId')}
                    className={`fs--1 form-control form-control-sm ${
                      errors.taxId ? 'is-invalid' : ''
                    }`}
                    type='text'
                    mask={taxIdFormat}
                    onChange={(e: any) => setTaxId(e.target.value)}
                    value={taxId}
                    onBlur={(e: any) => {
                      if (taxId !== '') {
                        //alert(country);
                        dispatch(
                          fetchCompanyDetailsForAgentDelegation({
                            taxId: taxId,
                            country: profileDetails?.partnerCountry,
                          })
                        );
                      } else {
                        setCompanyName('');
                        setValue('companyName', '');
                        setAddress('');
                        setValue('address', '');
                        setEmail('');
                        setValue('email', '');
                        setAgentPartnerId('');
                        setValue('agentPartnerId', '');
                      }
                      setValue('taxId', e.target.value);
                      setTaxId(e.target.value);
                    }}
                  />
                  <div className='invalid-feedback'>
                    {errors.taxId?.message?.toString()}
                  </div>
                </Form.Group>
                  </div>*/}

              <div className='col-lg-6 mb-3 d-none'>
                <Form.Group controlId='tableComponent.companyName'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_2:companyName')}</Form.Label>
                  <Form.Control
                    disabled={true}
                    size='sm'
                    {...register('companyName')}
                    className={`fs--1 form-control ${
                      errors.companyName ? 'is-invalid' : ''
                    }`}
                    type='text'
                    onChange={(e) => setCompanyName(e.target.value)}
                    onBlur={(e) => {
                      setValue('companyName', e.target.value);
                      trigger('companyName');
                    }}
                    value={companyName}
                  />
                  <div className='invalid-feedback'>
                    {errors.companyName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className='col-lg-6 mb-3 d-none'>
                <Form.Group controlId='tableComponent.registeredEmail'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_2:emailAddress')}</Form.Label>
                  <Form.Control
                    size='sm'
                    {...register('email')}
                    className={`fs--1 form-control ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => {
                      setValue('email', e.target.value);
                      trigger('email');
                    }}
                    disabled={true}
                    value={email}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.partyName'>
                  <span className='mandatory'>*</span>
                  <Form.Label>
                    {requestType === '813'
                      ? t('resource_1:shipper')
                      : requestType === '812'
                      ? t('resource_1:consignee')
                      : t('resource_1:consignee') +
                        '/' +
                        t('resource_1:shipper')}
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    size='sm'
                    {...register('partyName')}
                    className={`fs--1 form-control ${
                      errors.partyName ? 'is-invalid' : ''
                    }`}
                    type='text'
                    onChange={(e) => setPartyName(e.target.value)}
                    onBlur={(e) => {
                      setValue('partyName', e.target.value);
                      trigger('partyName');
                    }}
                    value={partyName}
                  />
                  <div className='invalid-feedback'>
                    {errors.partyName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3 d-none'>
                <Form.Group controlId='tableComponent.partyEmail'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_2:emailAddress')}</Form.Label>
                  <Form.Control
                    size='sm'
                    {...register('partyEmail')}
                    className={`fs--1 form-control ${
                      errors.partyEmail ? 'is-invalid' : ''
                    }`}
                    type='email'
                    onChange={(e) => setPartyEmail(e.target.value)}
                    onBlur={(e) => {
                      setValue('partyEmail', e.target.value);
                      trigger('partyEmail');
                    }}
                    disabled={true}
                    value={partyEmail}
                  />
                </Form.Group>
              </div>
              {/*<div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.registeredEmail'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_2:emailAddress')}</Form.Label>
                  <Form.Control
                    size='sm'
                    {...register('email')}
                    className={`fs--1 form-control ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => {
                      setValue('email', e.target.value);
                      trigger('email');
                    }}
                    disabled={disableFields}
                    value={email}
                  />
                  <div className='invalid-feedback'>
                    {errors.email?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-6 mb-3'>
                <Form.Group
                  style={{ position: 'relative' }}
                  controlId='tableComponent.address'>
                  <span className='mandatory'>*</span>
                  <Form.Label>{t('resource_1:address')}</Form.Label>
                  <Form.Control
                    {...register('address')}
                    className={`fs--1 form-control ${
                      errors.address ? 'is-invalid' : ''
                    }`}
                    as='textarea'
                    onChange={(e) => {
                      dispatch(updateSelectedAddressResult(''));
                      setAddress(e.target.value);
                      _onAddressChange(e.target.value);
                    }}
                    onBlur={(e) => {
                      setValue('address', e.target.value);
                      trigger('address');
                    }}
                    disabled={true}
                    value={address}
                  />
                  <div className='invalid-feedback'>
                    {errors.address?.message?.toString()}
                  </div>

                  {address &&
                  !selectedAddressResult &&
                  !isAddressSuggesstionLoading &&
                  registrationAddressSuggesstion?.length > 0 ? (
                    <div
                      style={{ position: 'absolute' }}
                      className='w-100 rounded border bg-white  odex-registration-address d-flex flex-column justify-content-start align-items-start'>
                      {registrationAddressSuggesstion.map((v) => (
                        <div
                          className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                          key={v.placeId}
                          onClick={() => {
                            dispatch(updateSelectedAddressResult(v.text));
                            setAddress(v.text);
                          }}>
                          <FontAwesomeIcon
                            icon={faLocation}
                            className='text-primary me-2'
                          />
                          <span>{v.text}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div> */}

              {disableFields == false ? (
                //<div className='col-lg-12 mb-3'>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='tableComponent.input5'>
                    <Form.Label className='fs--1 d-flex flex-row'>
                      {/* <span className='mandatory'>*</span> */}
                      {isMandatory=='Y'?<span className='mandatory'>*</span>:''}
                      {'LOI Upload'}
                      {watch('fileName') && watch('fileName') != '' &&
                      <td>
                        <UploadIndicator style={{marginLeft:'20px'}} 
                            progress={loadingFileState} 
                            fail={loadingFileState===-1}/>
                      </td>    }
                    </Form.Label>
                    <input
                      id={`${docId}-input`}
                      title='Input'
                      type='file'
                      className={`fs--2 form-control ${
                        errors.byteArr ? 'is-invalid' : ''
                      }`}
                      disabled={disableFields}
                      accept='.pdf'
                      onClick={()=>{
                        setValue('fileName','')
                        setSelectedFileServerPath([null,null])
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleFileChange(e);
                        trigger('byteArr');
                        setLoiUpload(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className='invalid-feedback'>
                    {errors.byteArr?.message?.toString()}
                  </div>
                </div>
              ) : (
                //</div>
                ''
              )}

              {/* <div className='col-lg-6 mb-3 displayNone'>
                <Form.Group controlId='blPayment.input5'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:uploadDocument')}
                  </Form.Label>
                  <FormControl disabled={disableFields} type='file' onChange={handleFileChange} />
                  {/* <FileUpload /> */}
              {/*</Form.Group>*/}
              {/* </div>  */}

              {
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>
                      {'Customer ' + t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      maxLength={200}
                      value={state.customerRemarks}
                      onChange={(e) =>
                        setState({ ...state, customerRemarks: e.target.value })
                      }
                      disabled={!!matchPth}
                    />
                  </Form.Group>
                </div>
              }
              {!matchPth ? (
                ''
              ) : (
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>{t('resource_2:rejectionRemark')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      className='fs--1 form-control'
                      value={state.rejectRemark}
                      onChange={(e) =>
                        setState({ ...state, rejectRemark: e.target.value })
                      }
                      disabled={profileDetails?.partnerType !== 'liner'}
                    />
                  </Form.Group>
                </div>
              )}
              {agentAccessList && agentAccessList.length > 0 ? (
                <div
                  className='col-lg-12 mt-2 mb-2 demmurageTable'
                  style={{ zIndex: 2 }}
                >
                  {/*   <span className='badge bg-secondary mb-2'>
                    {"Grant / invoice Access"}
                  </span> */}
                  <DataTable
                    data={agentAccessList}
                    gridName='BL_ACCESS'
                    columns={blAccessColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='blAccess'
                    autoHeight={true}
                    staticHiddenCols={['agentAccessId', 'agentNominationId']}
                  />
                </div>
              ) : (
                ''
              )}

              {/* <fieldset className='col-lg-12 mb-2'>
                <legend>Grand BL / invoice Access</legend>
                <div className='row'>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input10'
                        label={
                          <Form.Label className='fs--1'>
                            <span>BL Access</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='BL Access'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isBlAccess}
                        onChange={onToggleBlAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input11'
                        label={
                          <Form.Label className='fs--1'>
                            <span>Freight Invoice</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='The PDF of the freight invoice is only available to the Bill to party. If the access is granted by the bill to party to the nominated Agent then only it will be visible for the agent'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isGrantFrtInvAccess}
                        onChange={onToggleGrantFrtInvAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input12'
                        label={
                          <Form.Label className='fs--1'>
                            <span>Time Pending Invoice</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='Time Pending Invoice'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isTimePendingInvoiceAccess}
                        onChange={onToggleTimePendingInvoiceAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                </div>
                
                
              </fieldset> */}

              {agentNominationId != 0 ? (
                <div className='col-lg-6 mb-2'>
                  <Form.Group>
                    <Form.Label>{t('resource_1:status')}</Form.Label>
                    <Form.Select
                      className='fs--1'
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setValue('status', e.target.value);
                      }}
                      disabled={disableFields}
                    >
                      <option
                        value='Completed'
                        selected={status == 'Completed' ? true : false}
                      >
                        Completed
                      </option>
                      <option
                        value='Submitted'
                        selected={status == 'Submitted' ? true : false}
                      >
                        Submitted
                      </option>
                      <option
                        value='Rejected'
                        selected={status == 'Rejected' ? true : false}
                      >
                        Rejected
                      </option>
                      <option
                        value='Cancelled'
                        selected={status == 'Cancelled' ? true : false}
                      >
                        Cancelled
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}
              {(dataCountry !== 'US') &&
              !disableFields &&
              false ? (
                <div className='col-lg-12 mb-2'>
                  <Form.Group>
                    <Form.Check
                      type='checkbox'
                      label={
                        <Form.Label className='fs--1'>
                          <span>
                            I accept Terms & Conditions to delegate the above
                            selected party
                          </span>
                        </Form.Label>
                      }
                      onClick={showTncDialog}
                      checked={termsAccepted}
                      //onChange={onToggleGrantFrtInvAccess}
                      //disabled={disableFields}
                    />
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}

              {profileDetails?.partnerType === 'liner'
                ? !instructionList
                    .filter((v: any) => docId && v?.docId === parseInt(docId))
                    .map(
                      (u: any) =>
                        u?.event === 'Completed' || u?.event === 'Rejected'
                    )
                    .includes(true) && ''
                : !matchPth &&
                  disableFields == false &&
                  !instructionList
                    .filter((v: any) => docId && v?.docId === parseInt(docId))
                    .map(
                      (u: any) =>
                        u?.event === 'Completed' || u?.event === 'Rejected'
                    )
                    .includes(true) && (
                    <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                      <Button
                        variant='falcon-primary me-2'
                        size='sm'
                        type='submit'
                        
                        disabled={!blId || (loadingFileState!=100 && loadingFileState>0) || (isMandatory == 'Y' && selectedFileServerPath[0] === null)}
                      >
                        <FontAwesomeIcon icon={faSave} className='me-2' />
                        {t('resource_1:submit')}
                      </Button>

                      {/* <Button
                        variant='falcon-danger'
                        onClick={() => {
                          dispatch(clearFields({}));
                          resetForm();
                          //formReset();
                          dispatch(toggleAddAgent(false));
                          dispatch(toggleNominationReq(false));
                          dispatch(updateSelectedTab(''));
                          dispatch(toggleGrantFrtInvAccess(false));
                          setTermsAccepted(false);
                        }}
                        size='sm'>
                        <FontAwesomeIcon icon={faRedo} className='me-2' />
                        {t('resource_1:reset')}
                      </Button> */}
                    </div>
                  )}
              {disableFields == true &&
              agentPartnerId != profileDetails.partnerId &&
              rbac.rbacDetails.nominactionCUD == 'Y' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    type='button'
                    disabled={status === 'Completed' ? !disableFields : true}
                    onClick={() => {
                      dispatch(toggleDisableFields(false));
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                    {t('resource_1:edit')}
                  </Button>
                </div>
              ) : agentNominationId &&
                agentPartnerId != profileDetails.partnerId &&
                rbac.rbacDetails.nominactionCUD == 'Y' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    disabled={
                      profileDetails.partnerCountry == 'US'
                        ? !agentNominationId
                        : profileDetails.partnerCountry == 'SG' ||
                          profileDetails.partnerCountry == 'ZA' ||
                          profileDetails.partnerCountry == 'JO'
                        ? !agentNominationId
                        : !agentNominationId
                    }
                    onClick={onSubmit}
                  >
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:submit')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    onClick={_onCloseForm}
                    size='sm'
                  >
                    <FontAwesomeIcon icon={faRedo} className='me-2' />
                    {t('resource_1:cancel')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Form>
          {
            <Modal
              show={tncDialog}
              onHide={closeTncDialog}
              className='modal-lg'
            >
              <Modal.Header className='p-2 pb-1'>
                <Modal.Title>{t('resource_1:disclaimer')}</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  overflow: 'auto',
                  fontSize: '12px',
                }}
              >
                <AgentDelegationTNC />
                <div className='col-lg-8 offset-2 mt-3 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary'
                    size='sm'
                    className='me-2'
                    onClick={() => {
                      setTermsAccepted(true);
                      setShowTncDialog(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:agree')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    size='sm'
                    onClick={() => {
                      setTermsAccepted(false);
                      setShowTncDialog(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} className='me-2' />
                    {t('resource_1:disagree')}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          }
          {
            <Modal
              show={showConfirmMsg}
              onHide={() => setShowConfirmMsg(false)}
            >
              <Modal.Header className='p-2 pb-1'>
                <Modal.Title className='h6'>
                  {location.pathname.split('/').includes('812')
                    ? 'Unable to sent request for export Bl. Do you want to send request to Shipper.'
                    : location.pathname.split('/').includes('813')
                    ? 'Unable to sent request for Import Bl. Do you want to send request to Consignee.'
                    : ''}
                </Modal.Title>
              </Modal.Header>

              <div
                style={{ marginBottom: '1rem' }}
                className='d-flex justify-content-center align-items-center'
              >
                <Button
                  variant='falcon-primary'
                  size='sm'
                  className='me-2'
                  onClick={() => {
                    setShowConfirmMsg(false);
                    if (location.pathname.split('/').includes('812')) {
                      navigate('/instruction/813');
                    } else {
                      navigate('/instruction/812');
                    }
                  }}
                  // disabled={inputValue.length > 0 ? false : true}
                >
                  Yes
                </Button>
                <Button
                  className='me-2'
                  variant='falcon-danger'
                  size='sm'
                  onClick={() => {
                    setDecisionFocus(true);
                    setShowConfirmMsg(false);
                    navigate('/instruction');
                  }}
                >
                  No
                </Button>
              </div>
            </Modal>
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default ConsigneeNominateAgent;
