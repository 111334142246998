import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getBadges } from "../dashboard/components/helpers";
import { trackRegistrationRequest } from "./actions/Registration";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

type FormValues = {
  registrationRequestId: string;
};

const validationSchema = Yup.object().shape({
  registrationRequestId: Yup.string()
    .required("Registration Request ID is required")
    .matches(/^[0-9]+$/, "Invalid Registration Request ID"),
});

const CheckStatus: any = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState("");
  const [isStatusDisabled, setIsStatusDisabled] = useState(true);
  const registrationRequestId = watch("registrationRequestId");
  const [inputReq, setInputReq] = useState("");
  const handleInputReqChange = (e: any) => {
    setInputReq(e.target.value);
  };
  const onSubmit = async (data: FormValues) => {
    const response: any = await dispatch(trackRegistrationRequest(inputReq));
    setStatus(response.payload.data);
    setIsStatusDisabled(false);
  };
  // Effect to disable status if request ID changes
  useEffect(() => {
    if (registrationRequestId === "") {
      setIsStatusDisabled(true); // Disable status field when request ID changes
      setStatus(""); // Clear previous status
    }
  }, [registrationRequestId]);
  return (
    <div className="container py-3">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Row containing both Registration Request ID and Status */}
        <Row className="mt-3 align-items-center justify-content-center">
          {/* Registration Request ID */}
          <Col xs="12" md="6" lg="6" className="mb-3">
            <Form.Group controlId="registrationRequestId">
              <Form.Label
                className="fs--1 d-block"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  marginBottom: "0.2rem",
                  textAlign: "left", // Align the label to the left
                }}
              >
                <span className="mandatory text-danger">*</span> Registration
                Request ID
              </Form.Label>
              <Form.Control
                type="text"
                {...register("registrationRequestId")}
                className={`fs--1 form-control ${
                  errors.registrationRequestId ? "is-invalid" : ""
                }`}
                style={{
                  fontSize: "1rem",
                  padding: "0.2rem",
                  width: "100%", // Full width within the column
                }}
                placeholder="Enter Request ID"
                value={inputReq}
                onChange={handleInputReqChange}
              />
              <div className="invalid-feedback">
                {errors.registrationRequestId?.message}
              </div>
            </Form.Group>
          </Col>
          {!isStatusDisabled && (
            <Col xs="12" md="2" lg="3" className="mb-3">
              <Form.Group>
                <Form.Label
                  className="fs--1 d-block"
                  style={{
                    fontSize: "1.2rem", // Match font size with Registration Request ID label
                    fontWeight: "bold",
                    marginBottom: "0rem",
                    marginTop: "0.5rem",
                    textAlign: "left", // Align the label to the left
                  }}
                >
                  Status
                </Form.Label>
                <div
                  style={{
                    fontSize: "1.2rem",

                    marginTop: "0rem",
                    fontWeight: "bold",
                    minHeight: "38px", // Approximate height of Form.Control
                    display: "flex",
                    alignItems: "center", // Align vertically with the Request ID field
                    justifyContent: "flex-start", // Align content to the left
                    paddingLeft: "0px", // Ensure no left padding
                  }}
                >
                  {getBadges(status)}
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>

        {/* Centered Button Below the Form */}
        <Row className="mt-4 justify-content-center">
          <Col xs="auto" className="text-center">
            <Button
              type="submit"
              variant="primary"
              style={{
                padding: "0.6rem 2rem", // Adjust padding for button size
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Check Status
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CheckStatus;
