import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getDemurrage,
  addDemurrage,
  getTrukerNameList,
  getPickupList,
  getDefoultPickuLocation,
  getAttachmentsOnly,
//  getCheckList,
  getDemurrageView,
} from "../../../dashboard/actions/instruction";
import { verify } from "crypto";
import { boolean } from "yup";

interface IDemurrageForm {
  senderEmail: string | null;
  intendedDate: string | null;
  truckerCode: string | null;
  country: string | null;
  status: string | null;
  requestedPartnerName: string | null;
}

interface IDemurrageContainerDetails {
  //vesselType: any;
  containerNo: string | null;
  IntendedDate: string | null;
  truckerCode: string | null;
  pickupLocation: string | null;
  truckerName: string | null;
  truckerId: string | null;
  internalCode: string | null;
}

interface IDemurrageDocAttachmentDetails {
  //vesselType: any;
  docId: string | null;
  fileName: any | null;
  byteArray: string | null;
}

interface IDemurrageList {
  list: any[];
  next: string | null;
  previous: string | null;
}

interface IDemurrageDocAttachmentList {
  list: any[];
  next: string | null;
  previous: string | null;
}

export interface IDemurrageDetails {
  selectedTruckerCode: any;
  data: IDemurrageList;
  attData: IDemurrageDocAttachmentList;
  isDemurrageDetailsLoading: boolean;
  dataLoading: boolean;
  demurrageForm: IDemurrageForm;
  selectedDate: any;
  docList: any[] | null;
  pickUpLocation: any[];
  truckerList: any[];
  selectedAttachmentName: any;
  selectedAttachmentBytrr: any;
  selectedAttachmentFilePath: any;
  selectedDocId: any;
  selectedPickupLocation: any;
  selectedTruckerName: any;
  selectedtruckerId: any;
  selectedinternalCode: any;
  selectedDefaultPickupLocation: any;
  selectedFileServerPath: any;
  checkList:any;
  linerDocs: Array<any>;
  
  
}

const initialState: IDemurrageDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  attData: {
    list: [],
    next: null,
    previous: null,
  },
  docList: null,

  pickUpLocation: [],
  checkList:[],

  dataLoading: true,
  isDemurrageDetailsLoading: false,
  demurrageForm: {
    senderEmail: "",
    intendedDate: "",
    truckerCode: "",
    country: "",
    status: "",
    requestedPartnerName: "",
  },
  selectedDate: {},
  selectedTruckerCode: {},
  selectedAttachmentName: {},
  selectedAttachmentBytrr: {},
  selectedDocId: {},
  selectedPickupLocation: {},
  selectedTruckerName: {},
  selectedtruckerId: {},
  truckerList: [],
  selectedinternalCode: {},
  selectedAttachmentFilePath: {},
  selectedDefaultPickupLocation: "",
  selectedFileServerPath :{},
  linerDocs: []
 
};

export const DemurrageSlice = createSlice({
  name: "demurrage",
  initialState,
  reducers: {
    updateDemurrageList: (state, action: PayloadAction<IDemurrageList>) => {
      state.data = action.payload;
    },
    updateSelectedDateDV: (state, action: PayloadAction<any>) => {
      state.selectedDate = action.payload;
    },
    updateSelectedtruckerCode: (state, action: PayloadAction<any>) => {
      state.selectedTruckerCode = action.payload;
    },
    updateSelectedAttachmentName: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentName = action.payload;
    },
    updateSelectedAttachmentBytrr: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentBytrr = action.payload;
    },
    updateSelectedDocId: (state, action: PayloadAction<any>) => {
      state.selectedDocId = action.payload;
    },
    updateAdttamentData: (state, action: PayloadAction<any>) => {
      state.attData.list = action.payload;
    },
    updateTruckerList: (state, action: PayloadAction<any>) => {
      state.truckerList = action.payload;
    },
    updateSelectedPickupLocation: (state, action: PayloadAction<any>) => {
      state.selectedPickupLocation = action.payload;
    },
    updateSelectedTruckerName: (state, action: PayloadAction<any>) => {
      state.selectedTruckerName = action.payload;
    },
    updateSelectedtruckerId: (state, action: PayloadAction<any>) => {
      state.selectedtruckerId = action.payload;
    },
    updateselectedInternalCode: (state, action: PayloadAction<any>) => {
      state.selectedinternalCode = action.payload;
    },
    updatePickupLocation: (state, action: PayloadAction<any>) => {
      state.pickUpLocation = action.payload;
    },
    updateSelectedAttachmentFilePath: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentFilePath = action.payload;
    },

    

    updateSelectedCheckList: (state, action: PayloadAction<any>) => {
      const row = state.checkList.find((item:any)=> item.docId==action.payload.docId)
      if(row){
        row.check=action.payload.check
     }
    },
    updateselectedCheckListRemark: (state, action: PayloadAction<any>) => {
      const row = state.checkList.find((item:any)=> item.docId==action.payload.docId)
      if(row){
        row.remarks=action.payload.remarks
     }
    },
   clearSelectedFileServerPath:(state)=>{state.selectedFileServerPath = {}},
    updateSelectedFileServerPath:(state, action:PayloadAction<any>)=>{
      state.selectedFileServerPath = action.payload
    },
    updateLinerVerify: (state, action: PayloadAction<any>) => {
      const row = state.attData.list.find((Item)=> Item.docId==action.payload.docId)
      if(row){
        row.isVerify=action.payload.verify
        row.fileName=action.payload.fileName
        row.actionBy=action.payload.actionBy
        row.actionOn=action.payload.actionOn
      }
     
     
    },
    updateAttachmentList:(state, action)=>{

      state.docList = action.payload??[]
      state.attData.list = (action.payload??[]).map((x:any)=>({
        dndInstructionAttId: null,
        docId: x.id,
        fileName: "",
        verify:false,
        actionBy:"",
        actionOn:"",
        m: x.m,
      }))
    },
    setLinerAttachment: (state, action)=>{
      const row = state.linerDocs.find((i)=>i.docId == action.payload.docId)
      if(!row) return
      Object.entries(action.payload).forEach(([key, val])=>{
        if(Object.hasOwn(row, key))row[key] = val
      }) 
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addDemurrage.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addDemurrage.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getPickupList.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getPickupList.fulfilled, (state, action) => {
      state.pickUpLocation = action.payload;
    });

    builder.addCase(getDefoultPickuLocation.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getDefoultPickuLocation.fulfilled, (state, action) => {
      state.selectedDefaultPickupLocation = action?.payload?.toString() || "";
    });

    /* builder.addCase(getCheckList.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getCheckList.fulfilled, (state, action) => {
      state.checkList = action?.payload ??[];
    }); */

    builder.addCase(getDemurrage.pending, (state, action) => {
      state.data = {
        list: [],
        next: null,
        previous: null,
      };
      state.dataLoading = true;
    });
    builder.addCase(getDemurrage.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getDemurrageView.fulfilled, (state,action)=>{
      state.checkList = action.payload.data?.checkList

      state.linerDocs = (action.payload.data?.linerDocs??[]).map((x:any)=>({
        doReleasePdfId: x.doReleasePdfId,
        id: x.demurrageInstructionLinerAttId,
        name: x.docDesc,
        loading:0,
        docId: x.docId,
        fileName: x.fileName??'',
        m: x.isMandatory=='Y',
        serverPath: null,
        tempName: null
      }))
    })
    builder.addCase(getAttachmentsOnly.fulfilled,(state, action)=>{
      // resetting attData.list to initial value
      state.attData.list = state.docList?.map((x:any)=>({
        dndInstructionAttId: null,
        docId: x.id,
        fileName: "",
        actionBy:"",
        m: x.m,
      }))??[]
      if(action.payload?.chk) state.checkList = action.payload.chk
      if(!action.payload?.att)return
      action.payload?.att?.forEach((data:any)=>{
        const row = state.attData.list.find((item)=>item.docId == data.docId)
        if(!row)return
        row.fileName = data.fileName
        row.dndInstructionAttId = data.dndInstructionAttId
        row.filePath = data.filePath
        row.actionBy = data?.actionBy
        row.isVerify = data?.isVerify=="Y"
        state.selectedAttachmentFilePath[data.docId] = data.filePath
        state.selectedAttachmentName[data.docId] = {name: data.fileName}
      })

    });
  },
});
export const {
  updateDemurrageList,
  updateSelectedDateDV,
  updateSelectedtruckerCode,
  updateSelectedAttachmentBytrr,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateAdttamentData,
  updateSelectedPickupLocation,
  updateSelectedTruckerName,
  updateSelectedtruckerId,
  updateselectedInternalCode,
  updatePickupLocation,
  updateSelectedAttachmentFilePath,
  updateSelectedFileServerPath,
  clearSelectedFileServerPath,
  updateAttachmentList,
  updateLinerVerify,
  updateSelectedCheckList,
  updateselectedCheckListRemark,
  setLinerAttachment
  
} = DemurrageSlice.actions;

export default DemurrageSlice.reducer;
