import { faRemove, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import UploadIndicator from "./UploadIndicator"
import axios from "axios"

/**
 * Odex file upload handler
 */

interface IUploadThing{
  name:string;
  loading:number
  fulfilled:(name:string, serverPath:string, serverName:string)=>void;
  failed:()=>void; 
  pending:(loading:number)=>void;
  clear: ()=>void;
  selected: (name:string)=>void
  fileLimit:number;
  fileType:Array<string>;
  link?:string;
  labelWidth?:string;
  invalid?:boolean;
  disabled?:boolean;
  allowRemove?:boolean;
}

const formatName = (name: string, limit: number) =>{
  if(!limit) return name
  return name && name?.length >= limit ? name?.substring(0, limit) + '...' : name;
}

/**
 * A functional component that handles file uploads.
 *
 * @param {IUploadThing} props - The component props.
 * @param {string} props.name - The name state of the file.
 * @param {number} props.loading - The loading state of the file.
 * @param {function} props.fulfilled - A callback function called when the file is uploaded successfully.
 * @param {function} props.failed - A callback function called when the file upload fails.
 * @param {function} props.pending - A callback function called when the file is being uploaded.
 * @param {function} props.clear - A callback function called when the file is cleared.
 * @param {function} props.selected - A callback function called when a file is selected.
 * @param {number} props.fileLimit - The maximum file size limit in MB.
 * @param {string[]} props.fileType - The allowed file types in lowercase only.
 * @param {string} [props.link] - The link to the uploaded file.
 * @param {string} [props.labelWidth] - The width of the file name label.
 * @param {boolean} [props.invalid] - Whether the file is invalid.
 * @param {boolean} [props.disabled] - Whether the file input is disabled.
 * @param {boolean} [props.allowRemove] - Whether the file can be removed.
 * @return {JSX.Element} The JSX element representing the file upload component.
 */
const UploadThing: React.FC<IUploadThing> = ({ name, loading, fulfilled, failed, pending, clear, selected,
  fileLimit, fileType, link, labelWidth = '150px', invalid = false, disabled, allowRemove = true }) => {
  const inputRef: any = useRef(null)
  const [drag, setdrag] = useState(false)

  const handleFileChange = (file: File) => {

    if (file.size > fileLimit * 1000000) {
      toast.error(
        `File size is greater than ${fileLimit}MB. Please upload file below ${(fileLimit).toFixed(0)}MB`, {
        toastId: '802b877'
      }
      );
      return;
    }
    let fileExt = file.name.split('.').pop();

    if (!fileExt || !fileType.map((x)=>x.toLocaleLowerCase()).includes(fileExt?.toLowerCase())) {
      toast.error(`Please upload Only ${fileType.join(', ')} File${fileType.length > 1 ? '' : 's'}`, {
        toastId: 'a615407'
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      selected(file.name)

        axios
          .post('/api/edo-rest-v1/v1/view/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
            );
            pending(percentCompleted)
          },
        })
          .then((response) => {
          fulfilled(`${file.name}`, `${response.data.path}`,
            `${response.data.tempFileName}`)
        })
        .catch(() => {
          failed()
        });

    } catch (error) {
      console.error('File Upload Error: ', error);
      console.error('File Upload Error: ', error);
    }
  }

  return <div className="d-flex justify-content-start align-items-center">
    <input ref={inputRef}
      type='file'
      className="fs--2 d-none"
        onChange={(e)=>{
        const file = e.target.files?.[0]
            if(!file)return
        handleFileChange(file)
      }}
    />
    <label
      className='btn btn-sm btn-falcon-primary m-0 me-2'
            style={invalid?{borderColor:'#e63757'}:disabled?{cursor:'auto', opacity:'0.5'}:{}}
            title={name && name!=='' && allowRemove?"Clear File":"Upload File"}
            onClick={()=>{
              if(disabled)return
        inputRef.current.value = null
                if(name && allowRemove){
          clear()
          return
        }

                if(inputRef.current)inputRef?.current?.click();
      }}
      onDrop={(e) => {
        e.preventDefault();
        if(disabled)return
        const file = e.dataTransfer.files[0];
        setdrag(false)

        inputRef.current.value = null

        handleFileChange(file)
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if(disabled)return
        setdrag(true)
      }}
      onDragLeave={()=>{
        setdrag(false)
      }}
      onMouseLeave={()=>{
        setdrag(false)
      }}
    >
            {drag?
            'Drop Here':
            name && name!=='' && allowRemove?
            <FontAwesomeIcon style={{color:'#f5822a'}}icon={faTrash} />:
            <FontAwesomeIcon style={invalid?{color:'#e63757'}:{}} icon={faUpload} />}

    </label>
        <span className={`fs--1 ${link?'text-primary cursor-pointer':''} overflow-hidden`}
      style={{
        width: labelWidth,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}
      title={name}
        onClick={()=>{
          if(!link)return
        window.open(link)
      }}
    >{name}</span>
        {loading !== 0 &&<UploadIndicator
      style={{ marginLeft: '20px' }}
      progress={loading}
      fail={loading === -1}
    />}

  </div>
}

export default UploadThing