export const customDropdownStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "25px",
    height: "25px",
    fontSize: "12px",
    border: "none",
    padding: "0px",
    borderColor: state.isFocused ? "transparent" : "#ced4da",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    borderRadius: "4px",
    "&:hover": {
      borderColor: "#ced4da",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
    fontSize: "12px",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (base: any) => ({
    ...base,
    fontSize: "0.875rem",
    overflowY: "hidden",
    zIndex: 9999,
  }),
};
